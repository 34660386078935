/*=====================
     69 Footer
=======================*/
.footer-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 80px;
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .img-line {
    top: -70px;
  }

  .shape.right {
    top: -20%;
    height: 1200px;
    width: 1200px;
    right: -40%;
  }
  .footer-title {
    color: $color-white;
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
    & ~ .footer-content {
      margin-top: 26px;
    }
  }

  .app-coming-soon {
    color: $color-white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .main-sub-heading {
      display: block;
      font-family: "Nunito", sans-serif;
      font-size: 40px;
      font-weight: 700;
      text-align: center;
    }
    .main-icon-wrapper {
      text-align: center;
      display: flex;
      justify-content: center;
      gap: 20px;
      > span {
        display: inline-block;
        width: 70px;
        height: 70px;
      }
    }
  }

  .app-coming-soon.app-coming-soon-career {
    justify-content: start;
    .main-sub-heading {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
    }
    .main-icon-wrapper {
      > span {
        display: inline-block;
        width: 40px;
        height: 40px;
      }
    }
  }

  .footer-form {
    .form-control {
      width: 100%;
      background: $color-main-bg;
    }
  }

  .footer-links-col {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
    .footer-links-wrapper {
      .link-list {
        li ~ li {
          margin-top: 5px;
        }
        a {
          color: $color-white;
          &:hover {
            color: $color-gBlack3;
          }
        }
        li {
          span {
            cursor: pointer;
            color: $color-white;
            &:hover {
              color: $color-gBlack3;
            }
          }
        }
      }
      .social-profile {
        .social-link {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 7px;
          flex-wrap: wrap;
          a {
            display: inline-block;
            width: 42px;
            height: 42px;
            color: $color-white;
            transition: all 0.3s ease-in-out;
            &:hover {
              transform: rotate(-20deg) scale(1.2);
            }
          }
        }
      }
    }
  }

  .copyright-col {
    margin-top: 50px;

    .footer-logo {
      span {
        display: inline-block;
        cursor: pointer;
        img {
          max-height: 90px;
        }
      }
    }
    .foot-copyright-text {
      color: $color-white;
      margin-top: 20px;
      font-weight: 400;
      font-size: 14px;
    }
  }
  .copyright-col.copyright-career-col {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .footer-copyright-links {
      margin-bottom: 15px;
      a {
        color: $color-white;
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .footer-section {
    .footer-links-col {
      flex-wrap: wrap;
      justify-content: flex-start;
      .footer-links-wrapper {
        width: 33%;
        margin-bottom: 40px;
      }
    }
    .copyright-col {
      margin-top: 10px;
    }
    .shape.right {
      right: -70%;
      top: -10%;
    }
  }
}

@media only screen and (max-width: 575px) {
  .footer-section {
    .footer-links-col {
      .footer-links-wrapper {
        width: 49%;
      }
    }
    .footer-title {
      color: $color-white;
      font-size: 20px;
      line-height: 28px;
      font-weight: 700;
      & ~ .footer-content {
        margin-top: 15px;
      }
    }
    .shape.right {
      right: -150%;
    }
    .copyright-col.copyright-career-col {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      .footer-copyright-links {
        margin-bottom: 15px;
        a {
          color: $color-white;
          font-size: 14px;
        }
      }
      .foot-copyright-text {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 440px) {
  .footer-section {
    .footer-links-col {
      .footer-links-wrapper {
        width: 100%;
      }
    }
  }
}

.join-the-club-col {
  color: $color-white;
  .join-head {
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .join-text {
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  }
}
