.cups-container {
  position: relative;
  width: 500px;
  height: 400px;
  margin: 0 auto;
  color: #fff;
  font-family: sans-serif;
  text-align: center;
}

.cup {
  position: absolute;
  width: 150px;
  height: 150px;
  background-image: url("../assets/img/gaming/ghost.png");
  background-size: 100%;
  background-repeat: no-repeat;
  top: 110px;
  cursor: pointer;
  transition: all 0.5 ease-out;
}
.cup-2 {
  left: 175px;
}
.cup-3 {
  left: 350px;
}

.pf-ball {
  position: absolute;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  left: 235px;
  top: 270px;
  border-radius: 50%;
  background-image: url("../assets/img/gaming/pf_ball.gif");
  background-size: 100%;
  background-repeat: no-repeat;
}

.cups-container {
  .win-message {
    min-height: 25px;
    text-align: center;
    .glitch {
      display: block;
      font-size: 30px;
      color: $color-white;
      line-height: 32px;
      font-family: "Source Code Pro", monospace;
      font-weight: 700;
      position: relative;
      &::before,
      &::after {
        display: block;
        width: 150px;
        content: attr(data-glitch);
        position: absolute;
        top: 0;
        left: calc(50% - 75px);
        opacity: 0.8;
      }

      &::before {
        animation: glitch-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
          infinite;
        color: $color-3-green;
        z-index: -1;
      }

      &::after {
        animation: glitch-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          reverse both infinite;
        color: $color-1-blue;
        z-index: -2;
      }
      @keyframes glitch-color {
        0% {
          transform: translate(0);
        }

        20% {
          transform: translate(-2px, 2px);
        }

        40% {
          transform: translate(-2px, -2px);
        }

        60% {
          transform: translate(2px, 2px);
        }

        80% {
          transform: translate(2px, -2px);
        }

        to {
          transform: translate(0);
        }
      }
      &.lost-glitch {
        &::before {
          color: $color-5-red;
        }
        &::after {
          color: $color-white;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .cups-container {
    width: 330px;
    height: 330px;
  }
  .cup {
    width: 100px;
    height: 100px;
  }
  .cup-2 {
    left: 115px;
  }
  .cup-3 {
    left: 230px;
  }
  .pf-ball {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    left: 155px;
    top: 230px;
  }
}
