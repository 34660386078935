@import "../assets/abstracts/variables";
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined");

@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Anta&family=DM+Serif+Display:ital@0;1&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Orbitron:wght@400..900&family=Pixelify+Sans:wght@400..700&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");
// box-shadow: inset -2px -2px 6px $color6, inset 2px 2px 6px #abaeb3;
// box-shadow: 0px 4px 10px #d1dfec, 0px 2px 2px rgba(151, 151, 151, 0.25);
@import "../assets/layout/footer";
@import "../assets/layout/header";
@import "../assets/animations";

body {
  background: $color-main-bg !important;
  text-transform: lowercase !important;
  scroll-behavior: smooth;
  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: $color-black;
  margin: 0;
  padding: 0;
}

.body-no-scroll {
  overflow: hidden;
  position: relative;
}

.body-iphone-no-scroll {
  overflow: hidden;
  position: fixed;
}

.comp-name {
  display: inline !important;
  margin: 0 !important;
  padding: 0 !important;
  text-transform: none !important;
  white-space: nowrap;
  &.comp-name-anime {
    display: inline-block !important;
  }
}

*:not(.comp-name) {
  text-transform: lowercase !important;
}

.transition-all {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.error-text {
  display: inline-block;
  text-align: left;
  width: 100%;
  padding-left: 15px;
  color: $color-5-red;
  font-size: 12px;
  font-weight: 400;
}

.scrollbar-success {
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-2-purple;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-2-purple-lt;
    border-radius: 5px;
  }
}

.material-symbols {
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    font-family: "Material Symbols Outlined", Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
  }
}

.section-bg {
  background: $color-main-bg !important;
  position: relative;
}

.page-not-found-section {
  padding-top: 250px;
  padding-bottom: 150px;
  img {
    display: block;
    margin: 0 auto;
  }
}

main {
  overflow: hidden;
  position: relative;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

.main-btn {
  align-items: center;
  background-color: $color-main-bg !important;
  border: 2px solid $btn-grad-1;
  border-radius: 30px;
  display: inline-flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  padding: 5px;
  overflow: hidden;
  position: relative;
  // z-index: 1;
  margin-top: 40px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 15px 4px rgba(75, 150, 255, 0.75);
  -webkit-box-shadow: 0px 0px 15px 4px rgba(75, 150, 255, 0.75);
  -moz-box-shadow: 0px 0px 15px 4px rgba(75, 150, 255, 0.75);
  animation: btnAnimation 1.4s linear infinite;
  cursor: pointer;
  span {
    background: $color-tr;
    border-radius: 10px;
    color: $color-white;
    padding: 10px 35px;
    font-size: 22px;
    line-height: 22px;
    width: 100%;
    text-align: center;
  }
  &.active {
    background-color: $color-3-green !important;
  }
}

.main-btn-2 {
  @extend .main-btn;
  animation: btnAnimation2 1.8s linear infinite !important;
}

.main-btn-3 {
  @extend .main-btn;
  animation: btnAnimation3 2.3s linear infinite !important;
}

.main-btn-wo-anim {
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  animation: none !important;
}

@keyframes btnAnimation {
  0% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-1;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-1;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-1;
    border: 2px solid $btn-grad-1;
  }
  25% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-2;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-2;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-2;
    border: 2px solid $btn-grad-2;
  }
  30% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-3;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-3;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-3;
    border: 2px solid $btn-grad-3;
  }
  50% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-4;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-4;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-4;
    border: 2px solid $btn-grad-4;
  }
  70% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-5;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-5;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-5;
    border: 2px solid $btn-grad-5;
  }
  100% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-1;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-1;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-1;
    border: 2px solid $btn-grad-1;
  }
}

@keyframes btnAnimation2 {
  0% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-3;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-3;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-3;
    border: 2px solid $btn-grad-3;
  }
  25% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-5;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-5;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-5;
    border: 2px solid $btn-grad-5;
  }
  30% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-1;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-1;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-1;
    border: 2px solid $btn-grad-1;
  }
  50% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-4;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-4;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-4;
    border: 2px solid $btn-grad-4;
  }
  70% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-2;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-2;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-2;
    border: 2px solid $btn-grad-2;
  }
  100% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-3;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-3;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-3;
    border: 2px solid $btn-grad-3;
  }
}

@keyframes btnAnimation3 {
  0% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-5;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-5;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-5;
    border: 2px solid $btn-grad-5;
  }
  25% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-2;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-2;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-2;
    border: 2px solid $btn-grad-2;
  }
  30% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-4;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-4;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-4;
    border: 2px solid $btn-grad-4;
  }
  50% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-3;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-3;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-3;
    border: 2px solid $btn-grad-3;
  }
  70% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-1;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-1;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-1;
    border: 2px solid $btn-grad-1;
  }
  100% {
    box-shadow: 0px 0px 15px 4px $btn-grad-tr-5;
    -webkit-box-shadow: 0px 0px 15px 4px $btn-grad-tr-5;
    -moz-box-shadow: 0px 0px 15px 4px $btn-grad-tr-5;
    border: 2px solid $btn-grad-5;
  }
}

/* button rules */

@import "../assets/buttonRules";

/* end button rules */

.shape {
  background: radial-gradient(
    34.98% 34.98% at 50% 50%,
    #725bda 0,
    rgba(6, 19, 45, 0) 100%
  );
  content: "";
  height: 1850px;
  left: -50%;
  opacity: 0.5;
  position: absolute;
  top: -20%;
  width: 1850px;
  z-index: -1;
  pointer-events: none;
}

.shape.right {
  left: auto;
  right: -50%;
  top: -70%;
}

.img-line {
  left: 0;
  position: absolute;
  top: -90px;
  z-index: -2;
  pointer-events: none;
}

.img-line-2 {
  left: 0;
  position: absolute;
  top: -85%;
  z-index: -2;
}

// .parallax-section {
//   background-image: url(https://web-images.credcdn.in/_next/assets/images/home-page/neopop-image-mock.png);
//   min-height: 500px;
//   background-attachment: fixed;
//   background-position: center center;
//   background-repeat: no-repeat;
//   background-size: cover;
// }

.comm-section {
  height: 100vh;
  width: 100vw;
  position: relative;
  .container {
    height: 100%;
    & > .row {
      height: 100%;
    }
  }
}

.content-section {
  padding: 80px 0;
  position: relative;
  height: auto;
  width: 100%;
}

.white-color-section {
  color: $color-white;
}

.section-overlay-dark {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -4;
  overflow: hidden;
  pointer-events: none;
  // background-color: $color-main-bg-tr;
  background: linear-gradient(
    to bottom,
    rgba(7, 10, 41, 1) 0%,
    rgba(7, 10, 41, 0.98) 1%,
    rgba(7, 10, 41, 0.6) 20%,
    rgba(7, 10, 41, 0.6) 50%,
    rgba(7, 10, 41, 0.6) 80%,
    rgba(7, 10, 41, 1) 100%
  );
  &.above-main-bg {
    z-index: 0;
  }
}

.lottie-video-wrapper {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -5;
  overflow: hidden;
  video,
  .lottieVideo {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    // object-position: left top;
  }
}
@media only screen and (max-width: 767px) {
  .wallets-portal-section {
    .lottie-video-wrapper {
      video {
        object-position: 80% top;
      }
    }
  }
  .covered-section {
    .lottie-video-wrapper {
      video {
        object-position: 85% top;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .zero-charge-backs-section {
    .lottie-video-wrapper {
      video {
        object-position: 20% top;
      }
    }
  }
  .reminders-section {
    .lottie-video-wrapper {
      height: 100%;
      video {
        object-position: 75% bottom;
      }
    }
  }
}
.lottie-video-wrapper-content {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  position: absolute;
  left: -5px;
  right: -5px;
  z-index: -5;
  video,
  .lottieVideo {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
}
.credit-score-section {
  .lottie-video-wrapper-content {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    position: static;
    overflow: hidden;
    video,
    .lottieVideo {
      display: block;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      transform: scale(1.01);
    }
  }
}

.chaching-anywhere-section {
  .lottie-video-wrapper {
    height: 100%;
    video {
      object-position: 20% top;
    }
  }
}

@media only screen and (max-width: 991px) {
  .lottie-video-wrapper-content {
    position: static;
    transform: scale(1.01);
  }
}

.banner-section {
  color: $color-white;
  // background-image: url("../assets/img/banner/main-banner-bg.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
  .main-heading {
    display: block;
    font-family: "Nunito", sans-serif;
    font-size: 65px;
    font-weight: 700;
    em {
      font-weight: 500;
      color: $color-3-green;
    }
    .grad-text {
      color: #01a4b3;
      // background: linear-gradient(
      //   to right,
      //   #feb904 0%,
      //   #ff66b4 50%,
      //   #ef1010 75%,
      //   #01a4b3 100%
      // );
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }
  .main-content {
    font-size: 26px;
    line-height: 26px;
    display: block;
    float: right;
    position: relative;
    color: $color-4-yellow;
    &::before {
      content: "no asterisk*";
      position: absolute;
      font-size: 14px;
      top: 27px;
      left: 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  .container {
    max-width: 100%;
    padding: 0 40px;
  }
}
@media only screen and (max-width: 480px) {
  .container {
    max-width: 100%;
    padding: 0 15px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-section {
    .mobile-margin-bott {
      margin-bottom: 90px;
    }
    .main-content {
      float: left;
    }
    .main-heading {
      font-size: 55px;
      line-height: 60px;
    }
    .main-content {
      font-size: 22px;
      line-height: 22px;
      &::before {
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .banner-section {
    .main-heading {
      font-size: 45px;
      line-height: 50px;
    }
    .main-content {
      font-size: 18px;
      line-height: 18px;
      &::before {
        font-size: 12px;
      }
    }
  }
}

.section-content-wrapper {
  width: 100%;
}

.section-heading {
  display: block;
  font-family: "Nunito", sans-serif;
  font-size: 46px;
  line-height: 56px;
  font-weight: 700;
  margin-bottom: 50px;
  small {
    display: block;
    font-size: 36px;
    line-height: 46px;
  }
}

.section-content {
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
}

.story-section {
  .section-heading {
    font-size: 37px;
    small {
      font-size: 32px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .section-heading {
    font-size: 40px;
    font-weight: 600;
    line-height: 45px;
    small {
      font-size: 28px;
      line-height: 36px;
    }
  }

  .section-content {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }

  .story-section {
    .section-heading {
      font-size: 34px;
      small {
        font-size: 28px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .section-heading {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    small {
      font-size: 22px;
      line-height: 28px;
    }
  }

  .section-content {
    font-size: 16px;
    line-height: 24px;
  }

  .story-section {
    .section-heading {
      font-size: 26px;
    }
  }
}

.chaching-anywhere-section {
  // background-image: url("../assets/img/banner/chaching-anywhere-bg.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: top right;
  padding-bottom: 250px;
  height: auto;
  // min-height: 950px;
  // .container {
  //   min-height: 950px;
  //   > .row {
  //     min-height: 950px;
  //   }
  // }
  .section-heading {
    margin-top: 100px;
    span.chaching-logo-text {
      display: inline-block;
      width: 100%;
      max-width: 230px;
    }
  }
  .section-content {
    margin-bottom: 200px;
  }
  .tab-section {
    margin-top: 30px;
    .nav-tabs {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      border-bottom: 1px solid $color-tr;
      button {
        padding: 17px 33px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        text-transform: lowercase;
        color: $color-2-purple;
        background: $color-tr;
        border: none;
        border-bottom: 1px solid $color-tr;

        &.active,
        &:focus,
        &:hover {
          color: $color-white;
          background: $color-tr;
          border: none;
          border-bottom: 1px solid $color-white;
        }
      }
    }
    .tab-pane {
      position: relative;
      padding: 20px 0px;
      z-index: 1;
    }
    .tab-content {
      overflow: hidden;
    }
  }

  .vendor-logos-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;
    span {
      // background-color: $color-gBlack15;
      padding: 10px;
      min-width: 24%;
      max-width: 24%;
      height: 60px;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .section-overlay-dark {
    background: linear-gradient(
      to bottom,
      rgb(7, 10, 41) 0%,
      rgba(7, 10, 41, 0.98) 1%,
      rgba(7, 10, 41, 0.3) 20%,
      rgba(7, 10, 41, 0.3) 50%,
      rgba(7, 10, 41, 0.4) 90%,
      rgba(7, 10, 41) 100%
    );
  }
}

@media only screen and (max-width: 991px) {
  .chaching-anywhere-section {
    // min-height: 1100px;
    background-position: bottom left;
    height: auto;
    padding-bottom: 150px;
    // padding-bottom: 60px;
    .section-heading {
      margin-top: 150px;
      margin-bottom: 40px;
      span.chaching-logo-text {
        display: inline-block;
        max-width: 230px;
      }
    }
    .tab-section {
      margin-top: 0px;
    }
    .section-content + img {
      margin-top: 40px;
      transform: scale(1.3);
      margin: 130px auto;
    }
  }
}

@media only screen and (max-width: 480px) {
  .chaching-anywhere-section {
    .section-content {
      margin-bottom: 220px;
      color: $color-white;
    }
    .tab-section {
      .nav-tabs {
        button {
          padding: 15px 24px;
          font-size: 15px;
        }
      }
    }
    .vendor-logos-container {
      span {
        padding: 5px;
        min-width: 32%;
        max-width: 32%;
      }
    }
    .section-content + img {
      margin: 90px auto 80px;
    }
    .starry-btn {
      max-width: 47%;
      height: 3.5rem;
      .btn-text {
        font-size: 11px;
        padding: 0 12px;
      }
    }
  }
}

.rewards-section {
  background-color: #14100c;
  background-image: url("../assets/img/banner/rewards-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom right;
  .section-overlay-dark {
    z-index: 1;
    background: linear-gradient(
      to bottom,
      rgb(81, 52, 62) 0%,
      rgba(81, 52, 62, 0.98) 1%,
      rgba(7, 10, 41, 0) 20%,
      rgba(7, 10, 41, 0) 50%,
      rgba(7, 10, 41, 0) 90%,
      rgba(7, 10, 41, 0.1) 100%
    );
  }
}

@media only screen and (max-width: 767px) {
  .rewards-section {
    background-position: bottom left;
    height: auto;
    padding-bottom: 120px;

    .section-heading {
      margin-top: 120px;
      margin-bottom: 30px;
    }

    .section-heading + img {
      display: block;
      margin-top: 10px;
      margin-bottom: 15px;
      margin-left: auto;
      margin-right: -40px;
      max-width: 110%;
    }
  }
}

.brand-logo-section {
  width: 100%;
  min-height: 200px;
  background-color: $color-main-bg;
  padding-bottom: 20px;
  background-image: url("../assets/img/banner/main-banner-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  .brand-logo-heading {
    display: block;
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    margin: 25px auto 20px;
    text-align: center;
    color: $color-white;
  }
  .brand-logo-sub-heading {
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin: 10px auto;
    text-align: center;
    color: $color-white;
  }
  .brand-main-wrapper {
    gap: 20px;
    .brand-wrapper {
      display: inline-block;
      width: 80px;
      height: 80px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .brand-logo-section {
    .container {
      max-width: 100%;
    }
    .brand-main-wrapper {
      flex-wrap: wrap;
      .brand-wrapper {
        img {
          width: 200px;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-section {
    .brand-main-wrapper {
      gap: 10px;
      .brand-wrapper {
        img {
          width: auto;
          max-width: 100%;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}

.security-section {
  background-color: #14100c;
  background-image: url("../assets/img/banner/security-section-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  .section-content {
    em {
      span {
        color: $btn-grad-3;
        font-size: 30px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .security-section {
    padding-bottom: 60px;
    min-height: 990px;
    background-image: url("../assets/img/banner/security-section-mobile-bg.jpg");
    background-position: top center;
  }
}

@media only screen and (max-width: 767px) {
  .security-section {
    background-position: bottom left;
    height: auto;
    padding-bottom: 60px;
    min-height: auto;
    background-image: none;

    .section-heading {
      margin-top: 60px;
      margin-bottom: 20px;
    }

    .section-content + img {
      display: block;
      margin-top: 0px;
      margin-bottom: 35px;
      margin-left: auto;
      max-width: 100%;
    }
  }
}

.reminders-section {
  // background-image: url("../assets/img/banner/reminders-bg.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
  .section-heading {
    small {
      display: block;
    }
  }
}

@media only screen and (max-width: 991px) {
  .reminders-section {
    // background-position: top center;
    height: auto;
    padding-bottom: 120px;
    // background-image: url("../assets/img/banner/reminders-mobile-bg-1.jpg");

    .section-heading {
      margin-top: 70px;
      margin-bottom: 550px;
    }

    .section-heading + img {
      display: block;
      margin-top: 30px;
      margin-bottom: 90px;
      transform: scale(1.4);
    }

    .section-content {
      margin-top: 10px;
    }
  }
}

.story-section {
  background-image: url("../assets/img/banner/speckle-bg.png"),
    url("../assets/img/banner/main-banner-bg.jpg");
  background-size: contain, cover;
  background-repeat: repeat, no-repeat;
  background-position: center left;
  height: auto;
  padding-bottom: 80px;
  // background-image: url("../assets/img/banner/speckle-bg.png");
  // background-size: contain;
  .section-overlay-dark {
    z-index: 0;
    background: linear-gradient(
      to bottom,
      rgb(7, 10, 41) 0%,
      rgba(7, 10, 41, 0.98) 1%,
      rgba(7, 10, 41, 0.2) 20%,
      rgba(7, 10, 41, 0.2) 50%,
      rgba(7, 10, 41, 0.4) 80%,
      rgb(7, 10, 41) 100%
    );
  }
  .section-content-wrapper {
    padding-top: 80px;
    display: flex;
    flex-wrap: wrap;
  }
  .story-section-heading {
    text-transform: uppercase !important;
    small {
      display: block;
      text-align: center;
    }
  }
  .story-section-content {
    font-size: 36px;
    line-height: 56px;
    font-weight: 700;
    position: relative;
    color: $btn-grad-2;
    span {
      display: inline-block;
      margin-right: 12px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .story-section {
    background-position: center center;
    .story-section-content {
      font-size: 28px;
      line-height: 40px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .story-section {
    background-position: center center;
    .story-section-heading {
    }
  }
}

@media only screen and (max-width: 991px) {
  .story-section {
    background-position: center;
    .section-content-wrapper {
      img {
        display: block;
        margin: 0 auto;
      }
      img + .section-content {
        margin-top: 120px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .covered-section {
    padding-bottom: 35px;
    .section-heading {
      margin-bottom: 30px;
    }
  }
}

.store-section {
  background-image: url("../assets/img/banner/store-section-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
}

@media only screen and (max-width: 991px) {
  .store-section {
    background-position: center;
    height: auto;
    padding-bottom: 60px;
    background-image: url("../assets/img/banner/store-section-mobile-bg.jpg");

    .section-heading {
      margin-top: 60px;
    }

    .section-heading + img {
      display: block;
      margin-top: 30px;
      margin-bottom: 30px;
      // transform: scale(1.4);
    }

    .section-content {
      margin-top: 50px;
    }
  }
}

// .checker-container {
//   width: 100%;
//   height: 100%;
//   --color: rgba(114, 114, 114, 0.3);
//   background-color: transparent;
//   background-image: linear-gradient(
//       0deg,
//       transparent 24%,
//       var(--color) 25%,
//       var(--color) 26%,
//       transparent 27%,
//       transparent 74%,
//       var(--color) 75%,
//       var(--color) 76%,
//       transparent 77%,
//       transparent
//     ),
//     linear-gradient(
//       90deg,
//       transparent 24%,
//       var(--color) 25%,
//       var(--color) 26%,
//       transparent 27%,
//       transparent 74%,
//       var(--color) 75%,
//       var(--color) 76%,
//       transparent 77%,
//       transparent
//     );
//   background-size: 55px 55px;
// }

.faqs-section {
  padding-top: 60px;
  padding-bottom: 180px;
  position: relative;
  .shape {
    height: 1500px;
    left: -60%;
    top: -15%;
    width: 1500px;
  }
  .faq-wrapper {
    margin-bottom: 50px;
    .faq-question {
      color: $color-gBlack2;
      font-size: 18px;
      font-family: "Nunito", sans-serif;
      font-weight: 700;
      margin-bottom: 15px;
    }
    .faq-answer {
      color: $color-gBlack3;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
    }
  }
  .calculators-wrapper {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: $color-gBlack2;
    font-family: "Nunito", sans-serif;
    font-weight: 700;
    a {
      text-decoration: none;
      color: $color-gBlack3;
      font-size: 18px;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
    }
  }
}

.faqs-section.help-page {
  padding: 200px 0px;
  .faq-wrapper {
    .faq-question {
      font-family: "Source Code Pro", monospace;
    }
  }
}

.main-form-group {
  .form-control {
    min-height: 45px;
    color: $color-white;
    outline: none;
    transition: 0.35s;
    padding: 0px 12px;
    background-color: $color-main-bg;
    border-radius: 30px;
    border: 2px solid $color-main-bg;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 1),
      1px 1px 10px rgba(255, 255, 255, 0.6);
  }

  .form-control::placeholder {
    color: #999;
  }

  .form-control:focus.form-control::placeholder {
    transition: 0.3s;
    opacity: 0;
  }

  .form-control:focus {
    transform: scale(1.05);
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 1),
      1px 1px 10px rgba(255, 255, 255, 0.6), inset 2px 2px 10px rgba(0, 0, 0, 1),
      inset -1px -1px 5px rgba(255, 255, 255, 0.6);
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    color: $color-white;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23fff" fill-opacity="1" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
  }

  input[type="file"] {
    display: none;
  }

  .custom-file-upload-label {
    cursor: pointer;
    min-height: 45px;
    width: 100%;
    line-height: 45px;
    color: $color-white;
    outline: none;
    transition: 0.35s;
    padding: 0px 12px;
    background-color: $color-main-bg;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 30px;
    border: 2px solid $color-main-bg;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 1),
      1px 1px 10px rgba(255, 255, 255, 0.6);
  }
  &.transaction-date {
    input[type="date"] {
      position: relative;
      &::after {
        content: "transation date*";
        width: auto;
        height: 45px;
        line-height: 38px;
        position: absolute;
        top: 0;
        left: 12px;
        color: $color-placeholder;
      }
      &::-webkit-datetime-edit {
        color: transparent;
      }
      &:focus {
        &::-webkit-datetime-edit {
          color: $color-white;
        }
        &::after {
          content: none;
        }
      }
      &.not-empty {
        &::after {
          content: none;
        }
        &::-webkit-datetime-edit {
          color: $color-white;
        }
      }
    }
  }
}

.main-form-select {
  min-height: 45px;
  color: $color-white;
  outline: none;
  transition: 0.35s;
  padding: 0px 12px;
  background-color: $color-main-bg;
  border-radius: 30px;
  border: 2px solid $color-main-bg;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 1),
    1px 1px 10px rgba(255, 255, 255, 0.6);
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  &:focus {
    transform: scale(1.05);
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 1),
      1px 1px 10px rgba(255, 255, 255, 0.6), inset 2px 2px 10px rgba(0, 0, 0, 1),
      inset -1px -1px 5px rgba(255, 255, 255, 0.6);
  }
}

.main-form-select-white {
  color: $color-main-bg;
  background-color: $color-white;
  border: 2px solid $color-white;
  box-shadow: 3px 3px 5px rgba(119, 119, 119, 1),
    1px 1px 5px rgba(255, 255, 255, 0.6);
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23222222%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  &:focus {
    box-shadow: 3px 3px 5px rgb(119 119 119),
      1px 1px 5px rgba(255, 255, 255, 0.6), inset 2px 2px 5px rgb(119 119 119),
      inset -1px -1px 5px rgba(255, 255, 255, 0.6);
    border: 2px solid $color-white;
  }
}

.form-check {
  display: flex;
  margin-top: 20px;
  .form-check-input {
    appearance: none;
    overflow: hidden;
    min-width: 30px;
    min-height: 25px;
    aspect-ratio: 1/1;
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    border: 2px solid $color-3-green;
    position: relative;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    margin-right: 20px;
    background: transparent;
    &:focus {
      border-color: $color-3-green;
      box-shadow: -1px -1px 3px rgba(133, 187, 101, 1),
        1px 1px 3px rgba(34, 141, 87, 1);
    }

    &::before {
      position: absolute;
      inset: 0;
      content: "";
      font-size: 35px;
      transition: all 0.2s ease-in-out;
    }

    &:checked {
      border: 2px solid $color-3-green;
      background: linear-gradient(
        135deg,
        rgb(133, 187, 101) 0%,
        rgb(34, 141, 87) 100%
      );
      box-shadow: -5px -5px 30px rgba(133, 187, 101, 1),
        5px 5px 30px rgba(34, 141, 87, 1);
    }

    &:checked::before {
      background: linear-gradient(
        135deg,
        rgb(181, 255, 180) 0%,
        rgb(0, 167, 44) 100%
      );
    }
  }

  .form-check-label {
    color: $color-white;
    display: inline-block;
    cursor: pointer;
    a {
      color: $btn-grad-1;
    }
  }
}

/* pay page rules */

.speckle-bg {
  background-image: url("../assets/img/banner/speckle-bg.png");
  background-size: contain;
}

.pay-banner-section {
  color: $color-white;
  // background-image: url("../assets/img/banner/pay-main-banner.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center center;
  .main-heading {
    display: block;
    font-family: "Nunito", sans-serif;
    font-size: 50px;
    font-weight: 700;
  }
  .chaching-logo-text {
    display: inline-block;
    font-size: 65px;
    font-weight: 900;
    font-family: "Nunito", sans-serif;
    img {
      display: inline-block;
      margin: 0 auto;
    }
  }
}

.cash-register {
  max-width: 80%;
  display: block;
  margin: 30px auto;
}

.section-heading.pay-page {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  // line-height: 60px;
}
.section-content.pay-page {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-style: normal;
}

.shop-smarter-section {
  background-image: url("../assets/img/banner/shop-smarter-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .container {
    position: relative;
  }
}

.moneyback-section {
  background-image: url("../assets/img/banner/moneyback-pay-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .container {
    position: relative;
  }
}

.security-shield-section {
  .radar-loader-wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -2;
    overflow: hidden;
  }
  .radar-loader {
    position: relative;
    width: 700px;
    height: 700px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 25px 25px 75px rgba(0, 0, 0, 0.55);
    border: 1px solid #333;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      inset: 15%;
      background: transparent;
      border: 1px dashed #444;
      border-radius: 50%;
      box-shadow: inset -5px -5px 25px rgba(0, 0, 0, 0.25),
        inset 5px 5px 35px rgba(0, 0, 0, 0.25);
    }
    &::after {
      content: "";
      position: absolute;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      border: 1px dashed #444;
      box-shadow: inset -5px -5px 25px rgba(0, 0, 0, 0.25),
        inset 5px 5px 35px rgba(0, 0, 0, 0.25);
    }
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 100%;
      background: transparent;
      transform-origin: top left;
      animation: radar81 2s linear infinite;
      border-top: 1px dashed #e8a87c;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-2-purple;
        opacity: 0.7;
        transform-origin: top left;
        transform: rotate(-55deg);
        filter: blur(30px) drop-shadow(20px 20px 20px $color-2-purple);
      }
    }
  }

  @keyframes radar81 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

@media only screen and (max-width: 767px) {
  .security-shield-section {
    .section-content {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
    }
    .radar-loader {
      width: 300px;
      height: 300px;
      &::after {
        width: 80px;
        height: 80px;
      }
    }
  }
}

.crafted-personalisation {
  background-image: url("../assets/img/banner/crafted-personalisation-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .container {
    position: relative;
  }
  .mobile-img-wrapper {
    img {
      display: block;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 767px) {
  .crafted-personalisation {
    .mobile-img-wrapper {
      img {
        max-height: 400px;
        margin-top: 50px;
        margin-bottom: 40px;
      }
    }
  }
}

.chaching-everywhere-section {
  background-image: url("../assets/img/banner/chaching-everywhere-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .container {
    position: relative;
  }
}

/* common content pages */

.foggy-section {
  background-image: url("../assets/img/banner/main-banner-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.form-wrapper {
  .form-title {
    color: $color-white;
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
    font-family: "Source Code Pro", monospace;
    margin-bottom: 10px;
  }
  .form-sub-title {
    color: $color-white;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    font-family: "Source Code Pro", monospace;
    margin-bottom: 10px;
  }
  .main-form-group {
    margin-top: 16px;
  }
  .main-form-select {
    margin-top: 16px;
  }
  .form-check-label {
    font-family: "Source Code Pro", monospace;
  }
  .form-group-wrapper {
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .main-form-group {
      width: 49%;
    }
  }
  textarea {
    resize: none;
  }
}

@media only screen and (max-width: 575px) {
  .form-wrapper {
    .form-group-wrapper {
      .main-form-group {
        width: 100%;
      }
    }
  }
}

.content-head {
  color: $color-white;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  font-family: "Source Code Pro", monospace;
  margin-top: 40px;
  margin-bottom: 15px;
}

.section-content-wrapper {
  p {
    strong {
      font-weight: 700;
    }
    a {
      text-decoration: none;
      color: $btn-grad-1;
    }
  }
  .table {
    margin-top: 30px;
  }
}
.table-bordered > thead > tr th,
.table-bordered > tbody > tr td,
.table-bordered > tbody > tr th {
  background-color: $color-tr;
  color: $color-white;
}

@media only screen and (max-width: 991px) {
  .height-auto-section {
    height: auto;
    padding: 130px 0 80px;
  }
}

.grid-section-main-heading {
  margin-bottom: 120px;
}

.content-video-wrapper {
  max-width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  video {
    display: block;
    max-width: 100%;
    margin: 0;
  }
}

.section-grid-content {
  width: 100%;

  .grid-content-head {
    display: block;
    font-family: "Orbitron", sans-serif;
    font-size: 26px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 50px;
    font-optical-sizing: auto;
    font-style: normal;
  }
  .grid-content-text {
    display: block;
    font-family: "Source Code Pro", monospace;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 767px) {
  .section-grid-content {
    .grid-content-head {
      font-size: 22px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .grid-content-text {
      font-size: 18px;
      line-height: 26px;
      font-weight: 400;
    }
    .grid-content-text + .main-btn {
      margin-bottom: 60px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .section-grid-content {
    .grid-content-head {
      font-size: 20px;
      line-height: 38px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .grid-content-text {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.checkout-main-img-wrapper {
  margin: 0 auto;
  img {
    display: block;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 480px) {
  .checkout-main-img-wrapper {
    width: 100%;
    margin: 0 auto;
    img {
      display: block;
      margin: 0 auto;
      transform: scale(1.2);
    }
  }
}

.glass-card-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .glass {
    position: relative;
    width: 320px;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transition: 0.5s;
    border-radius: 10px;
    margin: 0 -45px;
    background: linear-gradient(#fff2, transparent);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 25px 25px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    transform: rotate(calc(var(--r) * 1deg));
    &::before {
      content: attr(data-text);
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50px;
      background: rgba(255, 255, 255, 0.05);
      display: flex;
      font-size: 22px;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 0px 0px 10px 10px;
    }
    .grid-content-head {
      margin-top: 20px;
      padding: 0 10px;
    }
    .grid-content-text {
      margin-bottom: 60px;
      padding: 0 10px;
    }
  }
  &:hover .glass {
    transform: rotate(0deg);
    margin: 0 10px;
  }
}

@media only screen and (max-width: 991px) {
  .glass-card-wrapper {
    flex-wrap: wrap;
    .glass {
      width: 100%;
      height: auto;
      margin-bottom: 30px;
      transform: rotate(0deg);
    }
    &:hover .glass {
      transform: none;
      margin: 0 10px;
    }
  }
}

.gradient-bg-text-row {
  .card {
    position: relative;
    width: calc(100% - 30px);
    height: 100%;
    background-color: $color-main-bg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 12px;
    border-radius: 10px;
    cursor: pointer;
    color: white;
  }

  .card::before {
    content: "";
    position: absolute;
    inset: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(-45deg, #e81cff 0%, #40c9ff 100%);
    opacity: 0.5;
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .card::after {
    content: "";
    z-index: -1;
    position: absolute;
    inset: 0;
    background: linear-gradient(-45deg, #fc00ff 0%, #00dbde 100%);
    transform: translate3d(0, 0, 0) scale(0.95);
    filter: blur(20px);
  }
  .card:hover::after {
    filter: blur(30px);
  }

  .card:hover::before {
    transform: rotate(180deg) scaleX(1.05) scaleY(1.05);
  }
}

.glass-frame-row {
  .card {
    max-width: 90%;
    margin: 0 auto;
    padding: 25px;
    border-radius: 1rem;
    overflow: visible;
    position: relative;
    border-radius: 10px;
    background: linear-gradient(#fff2, transparent);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 25px 25px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    color: $color-white;

    .starry-btn {
      max-width: 280px;
      margin-left: auto;
      margin-right: auto;
    }
    img {
      margin: 30px 0;
    }
  }
}

@media only screen and (max-width: 991px) {
  .glass-frame-row {
    .col-12 {
      margin-bottom: 60px;
    }
  }
}

.e-card-row {
  .e-card {
    margin: 0px auto;
    background: $btn-grad-tr-2;
    box-shadow: 0px 8px 28px -9px rgba(0, 0, 0, 0.45);
    position: relative;
    width: 100%;
    height: 480px;
    border-radius: 16px;
    overflow: hidden;
  }

  .wave {
    position: absolute;
    width: 540px;
    height: 700px;
    opacity: 0.6;
    left: 0;
    top: 0;
    margin-left: 10%;
    margin-top: -50%;
    background: linear-gradient(744deg, #af40ff, #5b42f3 60%, #00ddeb);
  }

  .icon {
    width: 3em;
    margin-top: -1em;
    padding-bottom: 1em;
  }

  .infotop {
    text-align: center;
    font-size: 20px;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    color: rgb(255, 255, 255);
    padding: 15px 15px;
    img {
      display: block;
      margin: 0 auto;
    }
  }

  .wave:nth-child(2),
  .wave:nth-child(3) {
    top: 210px;
  }

  .playing .wave {
    border-radius: 40%;
    animation: wave 3000ms infinite linear;
  }

  .wave {
    border-radius: 40%;
    animation: wave 55s infinite linear;
  }

  .playing .wave:nth-child(2) {
    animation-duration: 4000ms;
  }

  .wave:nth-child(2) {
    animation-duration: 50s;
  }

  .playing .wave:nth-child(3) {
    animation-duration: 5000ms;
  }

  .wave:nth-child(3) {
    animation-duration: 45s;
  }

  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.bg-popup-card-col {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
  .card {
    width: 30%;
    height: 100%;
    border: none;
    text-align: center;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    color: $color-white;
    .card-number {
      font-size: 60px;
      line-height: 76px;
      color: $color-white;
      font-family: "Anta", sans-serif;
      font-weight: 500;
      font-style: normal;
    }
    &:nth-child(1) {
      background-color: #673ab7;
      &:hover {
        transform: translateY(3px);
        box-shadow: 0 -8px 0px 0px #2196f3;
      }
    }
    &:nth-child(2) {
      background-color: $color-3-green;
      &:hover {
        transform: translateY(3px);
        box-shadow: 0 -8px 0px 0px $color-4-yellow;
      }
    }
    &:nth-child(3) {
      background-color: #29314f;
      &:hover {
        transform: translateY(3px);
        box-shadow: 0 -8px 0px 0px #f44336;
      }
    }

    .grid-content-head {
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .bg-popup-card-col {
    flex-wrap: wrap;
    .card {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
  }
}

.sample-swap-code-editor-col {
  margin-bottom: 30px;
  .w-tc-editor {
    border-radius: 30px;
    box-shadow: inset 2px 2px 10px rgb(0, 0, 0),
      inset -1px -1px 5px rgba(255, 255, 255, 0.6);
    min-height: 300px;
  }
}

.sample-swap-img-wrapper {
  max-width: 90%;
  margin: 0 auto;
  img {
    display: block;
    max-width: 90%;
    margin: 0 auto;
  }
}

.dashboard-img-wrapper {
  max-width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  img {
    display: block;
    margin: 0 auto;
    border-radius: 10px;
  }
}

/* end common content pages */

.extra-section-margin {
  margin-top: 110px;
}
.extra-section-margin-bott {
  margin-bottom: 110px;
}
.extra-section-padding {
  padding-top: 110px;
}
.extra-section-padding-bott {
  padding-bottom: 110px;
}
.about-us-section {
  padding-top: 200px;
  padding-bottom: 450px;
  .shape {
    top: -60%;
  }
}

.careers-main-section {
  background-image: url("../assets/img/banner/careers-main-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .container {
    position: relative;
  }
}

.career-cards-col {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1%;
  .card-container {
    color: $color-white;
    position: relative;
    cursor: pointer;
    width: 24%;
    margin-bottom: 20px;
    &::before,
    &::after {
      content: "";
      background-color: rgba(250, 181, 112, 0.298);
      position: absolute;
    }
    &::before {
      border-radius: 50%;
      width: 6rem;
      height: 6rem;
      top: 30%;
      right: 7%;
    }
    .career-card {
      width: 100%;
      height: 280px;
      padding: 1rem;
      background-color: rgba(255, 255, 255, 0.074);
      border: 1px solid rgba(255, 255, 255, 0.222);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      border-radius: 0.7rem;
      transition: all ease 0.3s;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-family: "Source Code Pro", monospace;
      font-optical-sizing: auto;
      font-style: normal;
      .title {
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0.1em;
      }
      &:hover {
        box-shadow: 0px 0px 20px 1px rgba(255, 187, 118, 0.247);
        border: 1px solid rgba(255, 255, 255, 0.454);
      }
      .careerBottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .apply-btn {
        display: block;
        font-size: 18px;
        text-align: center;
        border-top: 1px solid rgba(255, 255, 255, 0.222);
        padding-top: 12px;
        margin-top: 10px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .career-cards-col {
    gap: 2%;
    .card-container {
      width: 49%;
    }
  }
}
@media only screen and (max-width: 575px) {
  .career-cards-col {
    gap: 0%;
    .card-container {
      width: 100%;
    }
  }
}

.career-details-section {
  .section-content-wrapper {
    width: 90%;
    .content-head {
      margin-bottom: 30px;
    }
    ul {
      margin-bottom: 40px;
      li {
        margin-bottom: 20px;
      }
    }
  }
  .form-wrapper {
    .main-form-group {
      width: 100%;
      .form-control {
        width: 100%;
      }
    }
  }
}

.career-perks-section {
  .career-perks-col {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    .section-grid-content {
      width: 40%;
      margin-bottom: 60px;
      .grid-content-head {
        margin-bottom: 15px;
      }
      .grid-content-text {
        font-size: 20px;
        line-height: 26px;
        font-weight: 400;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .career-perks-section {
    .career-perks-col {
      .section-grid-content {
        width: 100%;
      }
    }
  }
}

.career-insights-section {
  padding-bottom: 120px;
  .career-insights-col {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    .section-grid-content {
      width: 100%;
      margin-bottom: 60px;
      display: flex;
      flex-wrap: nowrap;
      .grid-content-head {
        font-family: "Anta", sans-serif;
        font-size: 52px;
        line-height: 56px;
        margin-right: 30px;
      }
      .grid-content-text {
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .career-insights-section {
    .career-insights-col {
      .section-grid-content {
        flex-wrap: wrap;
        .grid-content-text {
          width: 100%;
        }
      }
    }
  }
}

.career-pride-section {
  .pride-wrapper {
    display: flex;
    align-items: center;
    .pride-image {
      width: 50%;
    }
    .pride-content {
      width: 50%;
      background-image: url("../assets/img/banner/quote-bg.png");
      background-size: 140px;
      background-repeat: no-repeat;
    }
    .pride-content-wrapper {
      padding: 30px;
      display: flex;
      flex-wrap: wrap;
      font-family: "Source Code Pro", monospace;
      height: 100%;
      .pride-text {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 60px;
      }
      .pride-name-role {
        font-size: 14px;
        line-height: 20px;
        span {
          display: block;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .career-pride-section {
    .pride-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .pride-image {
        width: 100%;
      }
      .pride-content {
        width: 100%;
      }
    }
  }
}

.pay-rewards-main {
  .pay-rewards-banner-section {
    position: relative;
    // .headerVideoOverlay {
    //   width: 100vw;
    //   height: 200px;
    //   background: linear-gradient(
    //     to bottom,
    //     rgba(0, 0, 0, 0.65) 0%,
    //     rgba(0, 0, 0, 0) 100%
    //   );
    //   position: absolute;
    //   z-index: -4;
    // }

    .section-heading {
      font-family: "DM Serif Display", serif;
      font-weight: 400;
      font-style: normal;
      font-optical-sizing: auto;
      font-style: normal;
      max-width: 500px;
      margin: 0 auto;
      span {
        display: block;
        &:nth-child(1) {
          font-size: 30px;
          line-height: 40px;
        }
        &:nth-child(2) {
          font-size: 80px;
          line-height: 90px;
          text-align: left;
          padding-left: 25%;
          color: #921319;
        }
        &:nth-child(3) {
          font-size: 45px;
          line-height: 50px;
          padding-left: 30%;
        }
        &:nth-child(4) {
          font-size: 80px;
          line-height: 90px;
          text-align: end;
          color: #37317f;
        }
      }
    }
  }
}

.join-the-champions-section {
  background-image: url("../assets/img/banner/join-the-champions-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
}

@media only screen and (max-width: 767px) {
  .pay-rewards-main {
    .pay-rewards-banner-section {
      .section-heading {
        span {
          &:nth-child(1) {
            padding-left: 25%;
          }
          &:nth-child(2) {
            text-align: left;
            padding-left: 10%;
          }
          &:nth-child(3) {
            padding-left: 50%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .pay-rewards-main {
    .pay-rewards-banner-section {
      .section-heading {
        span {
          &:nth-child(1) {
            font-size: 20px;
            line-height: 30px;
            padding-left: 0%;
          }
          &:nth-child(2) {
            font-size: 60px;
            line-height: 70px;
            text-align: left;
            padding-left: 0%;
          }
          &:nth-child(3) {
            font-size: 35px;
            line-height: 35px;
            padding-left: 0%;
          }
          &:nth-child(4) {
            font-size: 70px;
            line-height: 75px;
            text-align: start;
          }
        }
      }
    }
  }
}

.section-heading.pay-rewards-page {
  font-family: "Alfa Slab One", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400 !important;
}
.section-content.pay-rewards-page {
  font-family: "Alfa Slab One", serif;
  font-weight: 400 !important;
  font-style: normal;
}

@media only screen and (max-width: 991px) {
  .pay-reward-faq-section {
    height: auto;
    padding-bottom: 130px;
    padding-top: 80px;
  }
  // .pay-rewards-exclusive-section {
  //   height: auto;
  //   padding-bottom: 300px;
  //   padding-top: 300px;
  // }
}

.pay-reward-faq-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .pay-reward-faq-wrapper {
    font-family: "Alfa Slab One", serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 400 !important;
    width: 100%;
    height: 100%;
    padding: 30px;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    .faq-heading {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 30px;
    }
    .faq-text {
      font-size: 22px;
      line-height: 28px;
      font-family: "DM Serif Display", serif;
    }
  }
  &:nth-child(2) .pay-reward-faq-wrapper {
    background: linear-gradient(135deg, #e8eb5b 0%, $color-main-bg-tr 100%);
  }
  &:nth-child(3) .pay-reward-faq-wrapper {
    background: linear-gradient(135deg, #ff009c 0%, $color-main-bg-tr 100%);
  }
  &:nth-child(4) .pay-reward-faq-wrapper {
    background: linear-gradient(135deg, #5ce1e6 0%, $color-main-bg-tr 100%);
  }
  &:nth-child(5) .pay-reward-faq-wrapper {
    background: linear-gradient(135deg, #ff914d 0%, $color-main-bg-tr 100%);
  }
}

.pay-rewards-exclusive-section {
  .section-content.pay-rewards-page {
    font-family: "DM Serif Display", serif;
    font-weight: 400;
    font-style: normal;
    font-optical-sizing: auto;
  }
  button {
    margin-bottom: 110px;
  }
}

.app-soon-section {
  position: relative;
}

.app-soon-main-wrapper {
  width: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  .app-soon-wrapper {
    width: calc(6000px);
    animation: 20s linear 0s infinite scrollAnimation;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    color: $btn-grad-3;
    text-shadow: 2px 2px 20px $btn-grad-tr-3;
    font-family: "DM Serif Display", serif;
    div {
      font-size: 22px;
      min-width: 138px;
      text-align: center;
      height: 30px;
      display: inline-block;
      white-space: nowrap;
    }
    span {
      font-size: 22px;
      min-width: 30px;
      text-align: center;
      height: 30px;
      display: inline-block;
    }
  }
}

@keyframes scrollAnimation {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-1200px);
  }
}

// .contact-main-section {
// }

.contact-cards-wrapper {
  .contact-card-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .contact-card {
      // font-family: "Alfa Slab One", serif;
      font-optical-sizing: auto;
      font-style: normal;
      font-weight: 400 !important;
      width: 100%;
      height: 100%;
      padding: 30px;
      border-radius: 30px;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
        rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      justify-content: center;
      > div {
        width: 100%;
      }
      .contact-heading {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
        width: 100%;
        font-family: "Orbitron", sans-serif;
        font-weight: 600;
        display: block;
      }
      .contact-text {
        display: block;
        font-size: 18px;
        line-height: 26px;
        width: 100%;
        a {
          color: $color-white;
        }
      }
    }
    &:nth-child(1) .contact-card {
      background: linear-gradient(135deg, #b76e79 0%, $color-main-bg-tr 100%);
    }
    &:nth-child(2) .contact-card {
      background: linear-gradient(135deg, #3cc5c9 0%, $color-main-bg-tr 100%);
    }
    &:nth-child(3) .contact-card {
      background: linear-gradient(135deg, #135029 0%, $color-main-bg-tr 100%);
    }
  }
}

@media only screen and (max-width: 991px) {
  .contact-main-section {
    height: auto;
    padding-top: 250px;
    padding-bottom: 90px;
  }
}

.documents-page-grid {
  .gradient-bg-text-row {
    .card {
      min-height: 100px;
      text-align: center;
      align-content: center;
      gap: 0;
      > span {
        font-size: 14px;
      }
    }
    .card:hover::before {
      transform: rotate(180deg) scaleX(1.03) scaleY(1.1);
    }
  }
}

.documents-page-wallets {
  .gradient-bg-text-row {
    .card {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0px;
      span {
        display: inline-block;
        padding: 10px;
        min-width: 24%;
        max-width: 24%;
        height: 60px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        img {
          display: block;
          margin: 0 auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  .card:hover::before {
    transform: none;
  }
}

@media only screen and (max-width: 767px) {
  .documents-page-wallets {
    .gradient-bg-text-row {
      .card {
        span {
          min-width: 49%;
          max-width: 49%;
        }
      }
    }
  }
}

.legal-content-section {
  .tabs-col {
    padding-top: 10px;
    .nav-pills {
      .nav-link {
        padding: 17px 15px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        text-transform: lowercase;
        color: $btn-grad-2;
        background: $color-tr;
        border: none;
        border-bottom: 1px solid $color-tr;
        border-radius: 0;

        &.active,
        &:focus,
        &:hover {
          color: $color-white;
          background: $color-tr;
          border: none;
          border-bottom: 1px solid $color-white;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .legal-content-section {
    .tabs-col {
      padding-bottom: 20px;
    }
  }
}

/* rewards page */

// .rewards-scrolling-text-section {
//   display: flex;
//   align-items: center;

//   .scrolling-text-wrapper {
//     // max-width: 800px;
//     display: flex;
//     margin: 40px auto;
//     position: relative;
//     padding-top: 50px;
//     height: 100%;
//     perspective: 450px;
//     overflow: hidden;
//     color: $color-4-yellow;
//     font-weight: 600;

//     .crawl {
//       // width: 100%;
//       height: 100%;
//       display: flex;
//       flex-wrap: wrap;
//       position: relative;
//       transform-origin: 50% 100%;
//       animation: crawl 15s linear;
//       // transform: rotateX(30deg) translateZ(0);
//       > span {
//         display: inline-block;
//         margin-right: 10px;
//         font-size: 60px;
//       }
//     }
//   }
// }

// @keyframes crawl {
//   0% {
//     /* The element starts below the screen */
//     top: 0;
//     /* Rotate the text 20 degrees but keep it close to the viewer */
//     transform: rotateX(40deg) translateZ(0);
//   }
//   100% {
//     /* This is a magic number, but using a big one to make sure the text is fully off the screen at the end */
//     top: -2000px;
//     /* Slightly increasing the rotation at the end and moving the text far away from the viewer */
//     transform: rotateX(42deg) translateZ(-1500px);
//   }
// }

/* gaming section rules */

@import "../assets/neonLagoon";
@import "../assets/phantomFind";
@import "../assets/whirlpool";
@import "../assets/meteorRise";
@import "../assets/pixelBrain";

.rewards-main-bg-section {
  background-image: url("../assets/img/banner/rewards-main-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.gaming-section {
  position: relative;
  padding-top: 200px;
  // padding-bottom: 300px;
  min-height: 1080px;
  // border-top: 1px solid $color-white;

  .game-names-wrapper {
    height: 100%;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    min-height: 560px;
    div {
      width: 100%;
      margin-bottom: 20px;
      cursor: pointer;
      position: relative;
      padding-left: 30px;
      img {
        max-height: 70px;
      }
      &.active {
        &::before {
          position: absolute;
          content: "";
          display: block;
          width: 30px;
          height: 30px;
          top: calc(50% - 15px);
          left: 0;
          background-size: 100%;
          -webkit-animation: linear infinite;
          -webkit-animation-name: leftRight;
          -webkit-animation-duration: 0.5s;
          animation: linear infinite;
          animation-name: leftRight;
          animation-duration: 0.5s;
        }
        @-webkit-keyframes leftRight {
          0% {
            left: 0;
          }
          50% {
            left: 3px;
          }
          100% {
            left: 0;
          }
        }
        @keyframes leftRight {
          0% {
            left: 0;
          }
          50% {
            left: 4px;
          }
          100% {
            left: 0;
          }
        }
      }
      &.neon-lagoon-name.active {
        &::before {
          background-image: url("../assets/img/gaming/neon-lagoon-arrow.png");
        }
      }
      &.phantom-find-name.active {
        &::before {
          background-image: url("../assets/img/gaming/phantom-find-arrow.png");
        }
      }
      &.meteor-rise-name.active {
        &::before {
          background-image: url("../assets/img/gaming/meteor-rise-arrow.png");
          top: calc(50% - 10px);
        }
      }
      &.pixel-brain-name.active {
        &::before {
          background-image: url("../assets/img/gaming/pixel-brain-arrow.png");
        }
      }
      &.game-name-dim {
        cursor: default;
      }
    }
  }

  .game-col {
    overflow: hidden;
    position: relative;
    .rewards-won {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 9999;
      height: 50px;
      line-height: 40px;
      left: calc(50% - 125px);
      top: 50%;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      > span {
        display: inline-block;
        margin-left: 5px;
        font-size: 22px;
        line-height: 20px;
        color: $color-white;
      }
      .img-blk {
        display: inline-block;
        align-items: center;
        height: 20px;
        img {
          width: 24px;
          height: 24px;
          margin-top: -2px;
        }
      }
    }
  }
  .game-wrapper {
    position: relative;
    width: 100%;
    min-height: 560px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // overflow: hidden;
    &.neon-lagoon-main-bg {
      background-image: url("../assets/img/gaming/neon-lagoon-bg.jpg");
    }
    &.phantom-find-main-bg {
      background-image: url("../assets/img/gaming/phantom-find-bg.jpg");
    }
    &.meteor-rise-main-bg {
      background-image: url("../assets/img/gaming/meteor-rise-bg.jpg");
    }
    &.pixel-brain-main-bg {
      background-image: url("../assets/img/gaming/pixel-brain-bg.jpg");
    }

    .game-placeholder {
      position: absolute;
      z-index: 1;
      width: 560px;
      height: 560px;
      left: calc(50% - 280px);
      bottom: 0;
      background-position: center;
      background-size: 100%;

      &.meteor-rise-placeholder {
        // margin: 0;
        // padding: 0;
        // width: 100vw;
        // height: 100vh;
        // position: absolute;
        // z-index: -5;
        // overflow: hidden;
        // background-image: url("../assets/img/banner/pay-rewards-video-poster.jpg");
        video,
        .lottieVideo {
          display: block;
          margin: 0 auto;
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          opacity: 0.6;
        }
        > div {
          opacity: 0.8;
        }
      }

      &.neon-lagoon-placeholder {
        background-image: url("../assets/img/gaming/neon-lagoon-placeholder.png");
        background-size: 215%;
        background-position-y: -100px;
      }
    }
    .actual-game {
      position: absolute;
      z-index: 2;
      .game-btn-wrapper {
        width: 100%;
        text-align: center;
        button {
          padding: 0.1em 0.25em;
          width: 175px;
          height: 70px;
          border: 10px solid #c7c6c6;
          border-radius: 0.3em;
          font-size: 12px;
          background-color: $color-black;
          cursor: pointer;
          display: inline-block;
          span {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 0.4em;
            width: 8.25em;
            height: 2.5em;
            border-radius: 0.2em;
            font-size: 1.5em;
            &:hover {
              transition: all 0.5s;
              transform: translate(0, 0.4em);
              box-shadow: 0 0 0 0 #fff;
            }
            &:not(hover) {
              transition: all 1s;
            }
          }
        }
        .game-price {
          display: block;
          width: 90px;
          margin: 5px auto;
          position: relative;
          font-size: 18px;
          line-height: 30px;
          padding-left: 30px;
          &::before {
            content: "";
            position: absolute;
            width: 30px;
            height: 30px;
            background-image: url("../assets/img/coin-icon-small.png");
            background-size: 100%;
            left: 0;
            top: 0;
          }
        }
      }
      &.neon-lagoon {
        left: calc(50% - 157px);
        top: 110px;
        .game-btn-wrapper {
          margin-top: 5px;
          button {
            background-color: $color-black;
            width: 315px;
            span {
              width: 289px;
              background-color: $color-2-purple;
              border: 0.08em solid #ddb7ff;
              box-shadow: 0 0.4em 0.1em 0.019em #6b25a8;
              color: $color-white;
              &:hover {
                box-shadow: 0 0 0 0 #6b25a8;
              }
            }
            &.disabled-btn {
              span {
                background-color: $color-gBlack4;
                border: 0.08em solid #e3e3e3;
                box-shadow: 0 0.4em 0.1em 0.019em $color-black;
                color: $color-white;
              }
            }
          }
        }
        .game-price {
          display: block;
          width: 90px;
          margin: 5px auto;
          position: relative;
          font-size: 18px;
          line-height: 26px;
          padding-left: 26px;
          &::before {
            content: "";
            position: absolute;
            width: 26px;
            height: 26px;
            background-image: url("../assets/img/coin-icon-small.png");
            background-size: 100%;
            left: 0;
            top: 0;
          }
        }
      }

      &.phantom-find {
        top: 25px;
        left: calc(50% - 265px);
        margin-top: 0;
        width: 530px;
        height: auto;
        .game-btn-wrapper {
          button {
            span {
              background-color: $color-1-blue;
              border: 0.08em solid #6ff1ff;
              box-shadow: 0 0.4em 0.1em 0.019em #08717c;
              color: $color-5-red;
              &:hover {
                box-shadow: 0 0 0 0 #08717c;
              }
            }
          }
        }
      }
      &.whirlpool {
        top: 0px;
        left: calc(50% - 250px);
        overflow: visible;
        width: 500px;
        height: 500px;
      }
      &.meteor-rise {
        top: 0px;
        left: calc(50% - 280px);
        overflow: hidden;
        width: 560px;
        height: 560px;
        .game-btn-wrapper {
          margin-top: 30px;
          button {
            span {
              background-color: $color-3-green;
              border: 0.08em solid #ccffaf;
              box-shadow: 0 0.4em 0.1em 0.019em #3e6f22;
              color: $color-white;
              &:hover {
                box-shadow: 0 0 0 0 #3e6f22;
              }
            }
          }
        }
      }
      &.pixel-brain {
        top: 0px;
        left: calc(50% - 280px);
        overflow: hidden;
        width: 560px;
        height: 560px;

        .game-btn-wrapper {
          button {
            span {
              background-color: $color-4-yellow;
              border: 0.08em solid #ffde77;
              box-shadow: 0 0.4em 0.1em 0.019em #b68b04;
              color: $color-5-red;
              &:hover {
                box-shadow: 0 0 0 0 #b68b04;
              }
            }
          }
        }
      }
    }
  }
  .blurry-bg {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -3;
    background-position: center;
    background-size: cover;
    top: 0px;
    &.neon-lagoon-bg {
      background-image: url("../assets/img/gaming/neon-lagoon-bg.jpg");
    }
    &.phantom-find-bg {
      background-image: url("../assets/img/gaming/phantom-find-bg.jpg");
    }
    &.meteor-rise-bg {
      background-image: url("../assets/img/gaming/meteor-rise-bg.jpg");
    }
    &.pixel-brain-bg {
      background-image: url("../assets/img/gaming/pixel-brain-bg.jpg");
    }
  }
}

@media only screen and (max-width: 767px) {
  .gaming-section {
    .game-wrapper {
      .game-placeholder {
        width: 360px;
        left: calc(50% - 180px);
        height: 520px;
        &.neon-lagoon-placeholder {
          background-position-y: -45px;
        }
        &.meteor-rise-placeholder {
          height: 560px;
        }
      }
      .actual-game {
        .game-btn-wrapper {
          button {
            width: 201px;
            border: 5px solid #c7c6c6;
            height: 47px;
            span {
              width: 185px;
              height: 33px;
              font-size: 16px;
            }
          }
        }
        &.neon-lagoon {
          left: calc(50% - 101px);
          top: 128px;
          .game-btn-wrapper {
            button {
              width: 201px;
              border: 5px solid #c7c6c6;
              height: 47px;
              span {
                width: 185px;
                height: 33px;
                font-size: 16px;
              }
            }
          }
          .game-price {
            width: 63px;
            font-size: 14px;
            line-height: 20px;
            padding-left: 20px;
            margin: 1px auto 2px;
            &::before {
              width: 18px;
              height: 18px;
            }
          }
        }
        &.phantom-find {
          left: calc(50% - 180px);
          width: 360px;
        }
        &.pixel-brain {
          left: calc(50% - 180px);
          width: 360px;
          height: auto;
        }
        &.meteor-rise {
          left: calc(50% - 180px);
          width: 360px;
        }
      }
    }
    .game-names-wrapper {
      min-height: auto;
      .div {
        width: 70%;
        margin: 5px auto;
      }
    }
  }
}

/* end gaming section rules */

/* start of store pages rules */

@import "../assets/shoppingPages";

/* end of store pages rules */

/* swap modal rules */
.modal-backdrop {
  background-color: $color-main-bg;
  --bs-backdrop-opacity: 0.7;
}
.swap-modal {
  backdrop-filter: blur(10px);
  .modal-content {
    position: relative;
    padding: 10px 30px 30px;
    border-radius: 50px;
    overflow: hidden;
    background-color: $color-tr;
    box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 1),
      inset -1px -1px 5px rgba(255, 255, 255, 0.6);
    color: $color-white;
    min-height: 580px;
    .swap-modal-close {
      cursor: pointer;
      position: absolute;
      width: 44px;
      height: 33px;
      top: 0px;
      right: -3px;
    }
    .modal-header {
      border-bottom: none;
      padding-bottom: 0px;
    }
    .modal-title {
      img {
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-right: 10px;
      }
    }
    .modal-body {
      position: relative;
      overflow: hidden;
      padding: 30px 0px 10px;
      .tagline-text {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 30px;
      }
      .swap-heads {
        display: block;
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        margin-bottom: 15px;
        .highglight-purple {
          color: $color-2-purple;
          font-weight: 700;
        }
      }
    }
  }
  .wallet-list-wrapper {
    padding: 20px 15px;
    position: absolute;
    border-radius: 30px;
    z-index: 999;
    background-color: $color-main-bg;
    width: 100%;
    max-width: 100%;
    top: 20px;
    left: 0;
    overflow: hidden;
    box-shadow: inset 2px 2px 10px rgb(0, 0, 0),
      inset -1px -1px 5px rgba(255, 255, 255, 0.6);
    .wallet-list-close {
      cursor: pointer;
      position: absolute;
      width: 40px;
      top: 0px;
      right: -7px;
    }
    .filter-list {
      margin-top: 10px;
    }
    .wallet-list {
      min-height: 250px;
      max-height: 300px;
      overflow-y: auto;
      scroll-behavior: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      &::-webkit-scrollbar-thumb {
        background: $btn-grad-1;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px #e0e0e0;
        background-color: $color-black;
        border-radius: 6px;
      }
      .list-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        border-bottom: 1px solid $color-main-bg;
        transition: all 0.3s ease-in-out;
        padding: 10px 0px;
        .list-item-img {
          display: inline-block;
          width: 50px;
          height: 50px;
          border-radius: 10px;
          overflow: hidden;
          img {
            max-width: 100%;
            margin: 0 auto;
            border-radius: 10px;
          }
        }
        .list-item-name {
          display: inline-block;
          padding-left: 20px;
          color: $color-white;
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          transition: all 0.3s ease-in-out;
        }
        &:hover {
          border-bottom: 1px solid $btn-grad-1;
          .list-item-name {
            color: $btn-grad-1;
          }
        }
      }
    }
  }
  .swap-currency-main-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .swap-currency-dropdown {
      width: 50%;
      position: relative;
      .currency-block {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 10px;
        margin-bottom: 10px;
        border-radius: 30px;
        border-bottom: 1px solid $color-white;
        @extend .transition-all;
        cursor: pointer;
        &:hover {
          border-bottom: 1px solid $color-2-purple;
        }
        .icon-wrap {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin-right: 20px;
          border-radius: 50%;
          background-color: $color-white;
          padding: 10px;
          box-shadow: inset 2px 2px 4px rgb(0, 0, 0),
            inset -1px -1px 5px rgba(255, 255, 255, 0.6);
          img {
            width: 20px;
            height: 20px;
            display: block;
            margin: 0 auto;
          }
        }
        .curr-text {
          font-size: 18px;
        }
      }
      .selected-currency-wrapper {
        border: 1px solid $color-white;
        border-radius: 30px;
        position: relative;
        &::after {
          @extend .material-symbols;
          content: "\e5cf";
          display: block;
          width: 20px;
          height: 20px;
          top: 10px;
          right: 15px;
          pointer-events: none;
        }
        .currency-block {
          margin-bottom: 0;
          &:hover {
            border-bottom: 1px solid $color-white;
          }
        }
        &.selected-currency-wrapper-sc {
          &::after {
            content: none;
            display: none;
          }
        }
      }
      .currency-list-wrapper {
        padding: 10px 0px 0px;
        background-color: $color-main-bg;
        position: absolute;
        width: 100%;
        z-index: 999;
        overflow-y: auto;
        max-height: 155px;
        border-radius: 20px;
        .currency-block {
          &:last-child {
            margin-bottom: 0;
          }
        }
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
      }
      .currency-balance-block {
        font-size: 15px;
        text-align: left;
        padding-left: 20px;
        margin-top: 5px;
        font-weight: 500;
        line-height: 27px;
      }
    }
    .swap-currency-amounts {
      width: 50%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      .currency-fields {
        width: 130px;
        margin-right: 15px;
        padding-left: 5px;
        &.main-form-group {
          .form-control {
            box-shadow: none;
            background-color: $color-tr;
            border-color: $color-tr;
            &:focus {
              box-shadow: 6px 6px 10px rgb(0, 0, 0),
                1px 1px 10px rgba(255, 255, 255, 0.6),
                inset 2px 2px 10px rgb(0, 0, 0),
                inset -1px -1px 5px rgba(255, 255, 255, 0.6);
              background-color: $color-main-bg;
              border-color: $color-main-bg;
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              display: none;
            }
            &.form-control-sc {
              &:focus {
                box-shadow: none;
                background-color: $color-tr;
                border-color: $color-tr;
              }
            }
          }
        }
        input {
          text-align: center;
          font-size: 20px;
          width: 130px;
          // box-shadow: none;
        }
        .receive-amount {
          min-height: 45px;
          color: $color-white;
          outline: none;
          padding: 0px 12px;
          border-radius: 30px;
          text-align: center;
          font-size: 20px;
          width: 130px;
          line-height: 45px;
        }
        .converted-amount {
          font-size: 18px;
          color: $color-3-green;
          font-weight: 600;
          margin-top: 3px;
        }
      }

      .max-amount {
        .starry-btn {
          height: 2rem;
          margin-top: 0;
          .btn-text {
            padding: 0 5px;
            font-size: 11px;
          }
        }
      }
    }
  }
  .swap-currency-divider {
    position: relative;
    min-height: 40px;
    .swap-icon-block {
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      left: calc(50%);
      > span {
        font-size: 40px;
      }
      &.up-icon {
        left: calc(50% - 15px);
        bottom: 25%;
      }
      &.down-icon {
        right: calc(50% - 15px);
        top: 25%;
      }
    }
    .seperator-line {
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      top: calc(50% - 1px);
      background-color: $color-white;
      opacity: 0.7;
    }
    .seperator-circle {
      background-color: $color-main-bg;
      width: 60px;
      height: 60px;
      position: absolute;
      left: calc(50% - 16px);
      top: -10px;
      border-radius: 50%;
    }
  }

  .swap-complete-wrapper {
    position: absolute;
    width: 100%;
    z-index: 9999;
    // background-color: #3d004d;
    // border: 1px solid $color-2-purple-lt;
    overflow: hidden;
    border-radius: 30px;
    top: 20px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: inset 2px 2px 10px rgb(0, 0, 0),
      inset -1px -1px 5px rgba(255, 255, 255, 0.6);
    .swap-completed-text {
      font-size: 35px;
      font-weight: 600;
      width: 100%;
      text-align: center;
    }
    .track-link {
      width: 100%;
      color: $color-1-blue;
      text-align: center;
      cursor: pointer;
    }
    .confetti-wrapper {
      width: 100%;
      position: absolute;
      transform: scale(1.3);
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

@media only screen and (max-width: 575px) {
  .swap-modal {
    .swap-currency-main-wrapper {
      flex-wrap: wrap;
      justify-content: center;
      .swap-currency-amounts {
        width: 100%;
        margin-top: 15px;
        justify-content: center;
      }
      .swap-currency-dropdown {
        width: 90%;
        .currency-balance-block {
          text-align: center;
        }
      }
    }
  }
}

/* end swap modal rules */

/* personal - bill payments */

.bill-payments-section {
  padding-top: 220px;
  padding-bottom: 200px;
  .bills-card-col {
    margin-bottom: 60px;
    .bill-pay-head {
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 30px;
      border-radius: 30px;
      padding: 10px 15px;
      max-width: 290px;
      box-shadow: inset 2px 2px 10px rgb(0, 0, 0),
        inset -1px -1px 5px rgba(255, 255, 255, 0.6);
    }
    .locked-sub-text {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 10px;
      border-radius: 15px;
      padding: 10px 15px;
      max-width: 90%;
      margin: 0 auto;
      box-shadow: inset 2px 2px 10px rgb(0, 0, 0),
        inset -1px -1px 5px rgba(255, 255, 255, 0.6);
    }
    .bills-main-card-wrapper {
      position: relative;
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
      padding: 15px;
      margin-bottom: 20px;
      border-radius: 65px;
      // box-shadow: rgba(255, 255, 255, 0.35) 0px 3px 10px;
      text-align: center;
      min-height: 320px;
      &.active {
        box-shadow: inset 2px 2px 10px rgb(0, 0, 0),
          inset -1px -1px 5px rgba(255, 255, 255, 0.6);
      }

      &.locked-card {
        padding-top: 0;
        picture {
          display: block;
          max-width: 90%;
          margin: 0 auto;
        }
      }
    }
    .cc-buttons-wrapper {
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        margin-top: 0;
      }
    }
    .saved-card-wrapper {
      width: 100%;
      margin-top: 15px;
      display: flex;
      padding: 20px;
      justify-content: flex-start;
      overflow-x: auto;
      align-items: center;
      gap: 30px;
      .saved-card {
        display: inline-block;
        cursor: pointer;
        img {
          max-width: 80px;
          display: block;
          margin: 0 auto;
          pointer-events: none;
        }
        .card-text {
          display: block;
          text-align: center;
          font-size: 13px;
          font-weight: 500;
          &.have-time {
            color: $color-3-green;
          }
          &.nearby {
            color: $color-4-yellow;
          }
          &.past-due {
            color: $color-red-light;
          }
        }
      }
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }
    .main-cc-form-wrapper {
      margin-top: 30px;
    }
    .credit-card-edit-wrapper {
      position: absolute;
      margin-top: 20px;
      width: calc(100% - 30px);
      min-height: 300px;
    }
    .credit-card-add-wrapper {
      position: absolute;
      margin-top: 20px;
      width: calc(100% - 30px);
      min-height: 300px;
    }
    .credit-card-payment-wrapper {
      position: absolute;
      margin-top: 20px;
      width: calc(100% - 30px);
      min-height: 300px;
      max-height: 500px;
      overflow-y: auto;
      padding-bottom: 20px;
    }
    .close-cc-screens {
      cursor: pointer;
      position: absolute;
      width: 36px;
      top: -32px;
      right: -4px;
      z-index: 99;
      &.payment-screen-close {
        top: 3px;
        right: 11px;
      }
    }
  }
  /* main payment screen */
  .checkout-details-wrapper-bills {
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    margin-top: 5px;
    .paying-to-wrapper {
      text-align: left;
      margin-bottom: 15px;
      .checkout-details-head {
        display: block;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        color: $color-1-blue;
      }
      .paying-to-card {
        display: block;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 5px;
        width: 100%;
      }
      .payment-text {
        display: block;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 5px;
        width: 100%;
      }
      .autopay-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .autopay-text {
          display: inline-block;
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 5px;
          padding-right: 10px;
          &:last-child {
            &:hover {
              color: $color-1-blue;
            }
          }
        }
      }
    }
    .confirm-payment-wrapper {
      padding-top: 20px;
      .confirm-detail-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        border-bottom: 1px dotted $color-1-blue;
        padding-top: 5px;
        min-height: 60px;
        .confirm-text-head {
          display: inline-block;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 10px;
          width: 50%;
        }
        .confirm-text {
          display: inline-block;
          font-weight: 400;
          font-size: 18px;
          margin-bottom: 5px;
          width: 100%;
          width: 50%;
        }
        &:nth-child(odd) {
          color: $color-1-blue;
        }
      }
    }
    .accordion {
      margin-top: 10px;
      --bs-accordion-border-radius: 20px;
      --bs-accordion-inner-border-radius: 20px;
    }
    .accordion-item {
      background-color: $color-tr;
      position: relative;
      border: none;
      border-bottom: 1px solid $color-1-blue;
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius);
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      // &:after {
      //   content: "";
      //   position: absolute;
      //   bottom: 0px;
      //   right: 0px;
      //   height: 40px;
      //   width: 40px;
      //   box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1),
      //     10px 10px 5px rgba(201, 217, 232, 0.3);
      //   border-bottom-right-radius: 20px;
      //   pointer-events: none;
      // }
    }
    .accordion-button {
      flex-wrap: wrap;
      background-color: $color-tr;
      .header-wraper-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 40px);
      }
      .main-text,
      .payment-text {
        color: $color-white;
        display: block;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 5px;
        padding-right: 40px;
        width: 100%;
      }
      .payment-text {
        display: inline-block;
        padding-right: 0;
        width: auto;
      }
      .add-address {
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        padding-right: 40px;
      }
      &::after {
        position: absolute;
        right: 20px;
      }
      &:focus {
        box-shadow: none;
      }
      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
      }
      &:not(.collapsed) {
        background-color: $color-1-blue;
        color: $color-white;
        box-shadow: none;
        border-top-left-radius: var(--bs-accordion-inner-border-radius);
        border-top-right-radius: var(--bs-accordion-inner-border-radius);
      }
    }

    /* payment modes blocks */

    .payment-mode-wrapper {
      border-bottom: 1px dotted $color-1-blue;
      margin-bottom: 20px;
      color: $color-white;
      .mode-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        .head-text {
          font-size: 20px;
          line-height: 30px;
          font-weight: 600;
        }
        button {
          box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
            rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
            rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
          border: none;
          background-color: $color-white;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          padding: 4px 6px 8px;
          @extend .transition-all;
          &.default,
          &:hover {
            background-color: $color-1-blue;
            color: $color-white;
          }
        }
      }
      .mode-info-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        .mode-logo {
          display: inline-block;
          padding: 5px;
          max-width: 120px;
          max-height: 80px;
          margin-right: 10px;
          img {
            display: block;
            margin: 0 auto;
          }
          &.wallets {
            max-width: 50px;
            max-height: 50px;
            width: 50px;
            height: 50px;
          }
        }
        .mode-name {
          font-size: 16px;
          font-weight: 500;
        }
        .mode-creds {
          font-size: 18px;
          font-weight: 600;
        }
        &.default {
          .mode-logo {
            border: 1px solid $color-1-blue;
            border-radius: 8px;
          }
          .mode-name {
            color: $color-1-blue;
          }
          .mode-creds {
            color: $color-1-blue;
          }
        }
        .wallet-info-wrapper {
          display: flex;
          align-items: center;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
      &:last-child {
        border-bottom: none;
        margin-bottom: 0px;
      }
    }

    .currency-info-wrapper {
      .currency-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        .currency-logo {
          display: inline-block;
          width: 30px;
          height: 30px;
          margin-right: 10px;
          img {
            display: block;
            margin: 0 auto;
          }
        }
        .currency-name {
          font-size: 18px;
          font-weight: 600;
          color: $color-white;
        }
        &.default {
          .currency-logo {
            border: 1px solid $color-1-blue;
            padding: 4px;
            border-radius: 50%;
            background-color: $color-white;
          }
          .currency-name {
            color: $color-1-blue;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .calender-info-wrapper {
    }
    .amount-info-wrapper {
      .amount-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;

        .amount-name {
          font-size: 18px;
          font-weight: 600;
          color: $color-white;
        }
        .amount-field {
          font-size: 18px;
          font-weight: 600;
          color: $color-white;
          max-width: 150px;
        }
        &.default {
          .amount-logo {
            border: 1px solid $color-1-blue;
            padding: 4px;
            border-radius: 50%;
            background-color: $color-white;
          }
          .amount-name {
            color: $color-1-blue;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .payment-thankyou-wrapper {
    position: relative;
    margin-top: 20px;
    .payment-completed-img {
      width: 160px;
      height: 160px;
      // border: 2px solid $color-white;
      border-radius: 50%;
      padding: 10px;
      position: absolute;
      left: calc(50% - 80px);
      top: calc(50% - 90px);
      svg {
        max-width: 100%;
      }
    }
    .confetti-wrapper {
      width: 100%;
      // height: 410px;
      height: auto;
      margin: 0 auto;
      overflow: hidden;
      margin-top: -50px;
      img {
        max-width: 100%;
      }
    }
    .gift-received-wrapper {
      width: 100%;
      position: absolute;
      top: 35%;
      text-align: center;
      span {
        display: block;
        color: $color-white;
      }
      .gift-main-head {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 15px;
        font-weight: 500;
      }
      .gift-won {
        font-size: 40px;
        line-height: 42px;
        color: $color-3-green;
        font-family: "Anta", sans-serif;
        font-weight: 400;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 38px;
          height: 38px;
          display: inline-block;
          margin-right: 3px;
        }
        .voucher-brand {
          display: inline-block;
          width: 200px;
          height: 200px;
          border-radius: 20px;
          overflow: hidden;
          img {
            width: 200px;
            height: 200px;
            display: inline-block;
          }
        }
      }
    }
  }

  /* end main payment screen */
}
/* end personal - bill payments */

/* mockup rules */

.mobile-mockups-section {
  position: relative;
  width: 100%;
  .section-overlay-dark {
    z-index: 1;
    background: linear-gradient(
      to bottom,
      rgba(7, 10, 41, 1) 0%,
      rgba(7, 10, 41, 0.98) 1%,
      rgba(7, 10, 41, 0.1) 20%,
      rgba(7, 10, 41, 0.1) 50%,
      rgba(7, 10, 41, 0.1) 90%,
      rgb(80, 53, 64) 100%
    );
  }
  .mockup-img-wrapper {
    width: 100%;
    margin: 0 auto;
    img {
      display: block;
      width: 100%;
      max-width: auto;
      margin: 0 auto;
      transform-origin: center bottom;
    }
  }
}

@media only screen and (max-width: 767px) {
}

.mobile-mockups-parallax-section {
  width: 100%;
  background-image: url("../assets/img/banner/parallax-mockup-bg.jpg");
  min-height: 237px;
  background-attachment: fixed;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  .section-overlay-dark {
    z-index: 0;
    background-color: rgba(7, 10, 41, 0.2);
    height: 237px;
  }
}

@supports (-webkit-touch-callout: none) {
  .mobile-mockups-parallax-section {
    width: 100%;
    background-image: url("../assets/img/banner/parallax-mockup-bg.jpg");
    min-height: 237px;
    background-attachment: scroll;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
    .section-overlay-dark {
      z-index: 0;
      background-color: rgba(7, 10, 41, 0.2);
      height: 237px;
    }
  }
}

/* login pages rules */
.logged-in-tabs {
  margin-top: 30px;
  .nav-tabs {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid $color-tr;
    button {
      padding: 17px 24px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      text-transform: lowercase;
      color: $color-white;
      background: $color-tr;
      border: none;
      border-bottom: 1px solid $color-tr;

      &.active,
      &:focus,
      &:hover {
        color: $color-2-purple;
        background: $color-tr;
        border: none;
        border-bottom: 1px solid $color-2-purple;
      }
    }
  }
  .tab-pane {
    position: relative;
    padding: 20px 0px;
    z-index: 1;
  }
  // .tab-content {
  //   overflow: hidden;
  // }
}

.setting-cards-col {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  .card {
    width: auto;
    box-shadow: inset 2px 2px 10px rgb(0, 0, 0),
      inset -1px -1px 5px rgba(255, 255, 255, 0.6);
    padding: 5px 10px;
    border-radius: 20px;
    background-color: $color-tr;
    color: $color-white;
    height: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .setting-cards-col {
    flex-wrap: wrap;
    .card {
      width: 32% !important;
      height: auto;
    }
  }
}

@media only screen and (max-width: 767px) {
  .setting-cards-col {
    .card {
      width: 100% !important;
      height: auto;
    }
  }
}

/* end login pages rules */

/* start of waitlist rules */

.waitlist-modal {
  backdrop-filter: blur(10px);
  .modal-content {
    position: relative;
    padding: 10px 0px 30px;
    border-radius: 50px;
    overflow: hidden;
    background-color: $color-tr;
    box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 1),
      inset -1px -1px 5px rgba(255, 255, 255, 0.6);
    color: $color-white;
    min-height: 300px;
    .waitlist-modal-close {
      cursor: pointer;
      position: absolute;
      width: 44px;
      height: 33px;
      top: 0px;
      right: -3px;
    }
    .modal-header {
      border-bottom: none;
      padding-bottom: 0px;
    }
    .modal-title {
      padding-left: 18px;
      img {
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-right: 10px;
      }
    }
    .modal-body {
      position: relative;
      overflow: hidden;
      padding: 30px 0px 10px;
      .form-wrapper {
        padding-left: 30px;
        padding-right: 30px;
        .form-title {
          display: inline-flex;
          span {
            color: $color-4-yellow;
            padding-left: 10px;
            padding-right: 10px;
            &.green-text {
              color: $color-3-green;
            }
          }
        }
        .input-info {
          font-size: 14px;
          display: inline-block;
          padding-left: 15px;
          margin-top: 5px;
          color: $color-gBlack3;
        }
        .main-form-group.date-of-birth {
          input[type="date"] {
            position: relative;
            &::after {
              content: "your birthday*";
              width: auto;
              height: 45px;
              line-height: 38px;
              position: absolute;
              top: 0;
              left: 12px;
              color: $color-placeholder;
            }
            &::-webkit-datetime-edit {
              color: transparent;
            }
            &:focus {
              &::-webkit-datetime-edit {
                color: $color-white;
              }
              &::after {
                content: none;
              }
            }
            &.not-empty {
              &::after {
                content: none;
              }
              &::-webkit-datetime-edit {
                color: $color-white;
              }
            }
          }
        }
        .main-form-select {
          color: $color-placeholder;
        }
        .form-check {
          input[type="checkbox"],
          label {
            pointer-events: none;
          }
        }
        .error-text {
          font-size: 15px;
        }
      }
      .waitList-thankyou {
        padding: 30px;
        position: relative;
        .confetti-wrapper {
          width: 100%;
          position: absolute;
          transform: scale(1.3);
          opacity: 0.5;
          pointer-events: none;
          display: flex;
        }
        h3 {
          margin-bottom: 40px;
        }
        p {
          font-size: 18px;
          margin-bottom: 40px;
          span {
            color: $color-1-blue;
            .num-candy {
              font-size: 38px;
              color: $color-7-orange;
            }
          }
          img {
            display: inline;
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .waitlist-modal {
    .modal-content {
      .modal-body {
        .form-wrapper {
          .form-title {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 5px;
          }
          .form-sub-title {
            font-size: 14px;
            span {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

// @media only screen and (max-width: 575px) {

// }

/* end of waitlist rules */

/* vouchers */
.rewards-main-bg-section {
  .vouchers-col {
    .nav-tabs {
      margin: 0 auto;
      margin-bottom: 30px;
      border-radius: 30px 30px 0px 0px;
      padding: 10px 15px;
      box-shadow: inset 2px 2px 10px rgb(0, 0, 0),
        inset -1px -1px 5px rgba(255, 255, 255, 0.6);
      border: none;
      .nav-item {
        &:focus,
        &:focus-visible,
        &:hover {
          border: none;
          outline: none;
          box-shadow: none;
        }
        .nav-link {
          font-size: 22px;
          font-weight: 600;
          text-align: center;
          width: 100%;
          color: $color-white;
          border: none;
          border: none;
          outline: none;
          box-shadow: none;
          &:focus,
          &:focus-visible,
          &:hover {
            border: none;
            outline: none;
            box-shadow: none;
          }
          &.active {
            background-color: transparent;
            color: $color-2-purple;
            border: none;
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
    .tab-content {
      width: 100%;
      border-radius: 0px 0px 0px 30px;
      padding: 20px;
      box-shadow: inset 2px 2px 10px rgb(0, 0, 0),
        inset -1px -1px 5px rgba(255, 255, 255, 0.6);
      padding-right: 0;
      .tab-pane {
        min-height: 340px;
        max-height: 340px;
      }
    }
    .vouchers-main-wrapper {
      padding-right: 20px;
      @extend .scrollbar-success;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      min-height: auto;
      overflow-y: auto;
      max-height: 340px;
      .voucher {
        width: 49%;
        max-width: 340px;
        height: auto;
        margin-bottom: 10px;
        position: relative;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 10px;
        padding-bottom: 60px;
        .voucher-img {
          border-radius: 10px 10px 0px 0px;
          overflow: hidden;
          img {
            border-radius: 10px 10px 0px 0px;
          }
        }
        .voucher-text {
          padding: 10px 10px 20px;
          color: $color-black;
          position: relative;

          .voucher-name {
            font-size: 18px;
            font-weight: 700;
          }
          .brand-name {
            font-size: 16px;
            font-weight: 500;
          }
          .location {
            display: inline-flex;
            position: absolute;
            top: 3px;
            right: 10px;
            font-size: 13px;
            line-height: 18px;
            align-items: center;
            .material-symbols-outlined {
              font-size: 20px;
            }
          }
          &.for-location {
            padding-top: 24px;
          }
        }
        .voucher-btns {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          // display: flex;
          // align-items: center;
          // justify-content: space-between;
          padding: 6px 10px 10px;
          border-top: 1px dashed $color-gBlack4;
          // background-color: rgba(0, 0, 0, 0.5);
          background-color: #bababa;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;

          button {
            padding: 0.1em 0.25em;
            background-color: $color-black;
            width: 49%;
            margin-right: 2%;
            height: 55px;
            border: 5px solid #bababa;
            border-radius: 0.3em;
            font-size: 10px;
            background-color: $color-black;
            cursor: pointer;
            display: inline-block;
            span {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              bottom: 0.4em;
              height: 2.8em;
              border-radius: 0.2em;
              font-size: 1.4em;
              width: 100%;
              background-color: $color-2-purple;
              border: 0.08em solid #cfd7ff;
              box-shadow: 0 0.4em 0.1em 0.019em #5e79ff;
              color: $color-white;
              &:hover {
                transition: all 0.5s;
                transform: translate(0, 0.4em);
                box-shadow: 0 0 0 0 #5e79ff;
              }
              &:not(hover) {
                transition: all 1s;
              }
            }
            &:last-child {
              margin-right: 0;
            }
            &.variant-1 {
              span {
                background-color: $color-1-blue;
                border: 0.08em solid #6ff1ff;
                box-shadow: 0 0.4em 0.1em 0.019em #08717c;
              }
              &:hover {
                span {
                  box-shadow: 0 0 0 0 #08717c;
                }
              }
            }
            &.variant-2 {
              span {
                background-color: $color-2-purple;
                border: 0.08em solid #ddb7ff;
                box-shadow: 0 0.4em 0.1em 0.019em #6b25a8;
              }
              &:hover {
                span {
                  box-shadow: 0 0 0 0 #6b25a8;
                }
              }
            }
            &.variant-3 {
              span {
                background-color: $color-4-yellow;
                border: 0.08em solid #ffde77;
                box-shadow: 0 0.4em 0.1em 0.019em #b68b04;
              }
              &:hover {
                span {
                  box-shadow: 0 0 0 0 #b68b04;
                }
              }
            }
            &.variant-4 {
              span {
                background-color: $color-5-red;
                border: 0.08em solid #f5757b;
                box-shadow: 0 0.4em 0.1em 0.019em #620409;
              }
              &:hover {
                span {
                  box-shadow: 0 0 0 0 #620409;
                }
              }
            }

            &.variant-5 {
              span {
                background-color: $color-7-orange;
                border: 0.08em solid #ffd29b;
                box-shadow: 0 0.4em 0.1em 0.019em #7b4606;
              }
              &:hover {
                span {
                  box-shadow: 0 0 0 0 #7b4606;
                }
              }
            }
            &.variant-6 {
              span {
                background-color: $color-3-green !important;
                border: 0.08em solid #d6ffbf !important;
                box-shadow: 0 0.4em 0.1em 0.019em #4a7f2b !important;
              }
              &:hover {
                span {
                  box-shadow: 0 0 0 0 #4a7f2b !important;
                }
              }
            }
          }
        }
        .expiry-block {
          position: absolute;
          display: inline-block;
          top: 8px;
          right: 0px;
          background-color: rgba(255, 255, 255, 0.5);
          font-size: 11px;
          color: $color-black;
          padding: 2px 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .rewards-main-bg-section {
    height: auto;
    padding-top: 200px;
    padding-bottom: 200px;
    .vouchers-col {
      margin-top: 40px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .rewards-main-bg-section {
    .vouchers-col {
      .nav-tabs {
        max-width: 380px;
      }
      .tab-content {
        max-width: 380px;
        margin: 0 auto;
      }
      .vouchers-main-wrapper {
        .voucher {
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .rewards-main-bg-section {
    .vouchers-col {
      .nav-tabs {
        max-width: 380px;
      }
      .tab-content {
        max-width: 380px;
        margin: 0 auto;
      }
      .vouchers-main-wrapper {
        .voucher {
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

/* end of vouchers */
