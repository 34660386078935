.meteor-container {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust based on your preference */
  overflow: hidden;
  background-size: contain;

  .meteor-path {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 400px;
    opacity: 0.9;
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
  }
  .scale-wrapper {
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 5%;
    span {
      position: relative;
      display: block;
      width: 50px;
      height: 20px;
      font-size: 10px;
      line-height: 20px;
      padding-left: 12px;
      &::before {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        width: 8px;
        left: 0;
        top: 10px;
        background-color: $color-white;
      }
    }
  }
}

.meteor {
  position: absolute;
  transition: bottom 0.1s linear; /* Smooth movement based on the multiplier updates */
  width: 50px;
}

.explosion {
  position: absolute;
  font-size: 2rem;
  width: 100px;
  height: 100px;
  left: calc(50% - 50px);
  top: 110px;
}

.crash-point {
  position: absolute;
  left: 5px;
  top: 0;
  font-size: 10px;
  display: none;
}

.multiplier {
  position: absolute;
  width: 100%;
  top: 40%;
  text-align: center;
  span {
    display: block;
  }
  .multiplier-text {
    font-size: 50px;
    font-weight: 700;
  }
  .payout-text {
    font-size: 20px;
    font-weight: 700;
    color: $color-3-green;
  }
}

.previous-cashout {
  position: absolute;
  top: 5px;
  width: 90%;
  padding: 5px 8px;
  span {
    display: inline-block;
    padding: 5px 8px;
    background-color: $color-3-green;
    font-size: 14px;
    line-height: 15px;
    color: $color-main-bg;
    margin-right: 4px;
    border-radius: 10px;
    // &:first-child {
    //   background-color: $color-tr;
    //   color: $color-white;
    // }
  }
}

@media only screen and (max-width: 767px) {
  .meteor-container .scale-wrapper span {
    width: 42px;
    font-size: 8px;
  }
  .meteor {
    width: 40px;
  }
}

.mr-win-upto {
  position: absolute;
  top: 38px;
  left: 0;
  width: 100%;
  padding: 5px 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: heartbeat 1s infinite;
  img {
    display: block;
    margin: 0 auto;
    max-width: 70%;
  }
  span {
    display: inline-block;
    padding: 5px;
    font-size: 32px;
    line-height: 34px;
    margin-right: 4px;
  }
  // img {
  //   display: inline-block;
  //   width: 34px;
  //   height: 34px;
  // }
}

@keyframes heartbeat {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
  }
  25% {
    transform: scale(1.04) rotate(-2deg);
  }
  50% {
    transform: scale(1) rotate(0deg);
  }
  75% {
    transform: scale(1.04) rotate(2deg);
  }
}

@media only screen and (max-width: 575px) {
  .mr-win-upto {
    span {
      font-size: 16px;
    }
  }
}
