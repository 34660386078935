$icon_width: 105px; // 315
$icon_height: 95px;
$icon_width_mobile: 67px; // 201
$icon_height_mobile: 60px;
$num_icons: 9;

.debug {
  position: fixed;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px;
  background: $color-black;
  border: 1px solid rgba(black, 0.3);
  font-family: monospace;
  font-size: 1.6rem;
}

.slots {
  position: relative;
  width: 3 * $icon_width;
  height: 3 * $icon_height;
  display: flex;
  justify-content: space-between;
  padding: 0;
  background: $color-tr;

  @keyframes win1 {
    0% {
      background: linear-gradient(45deg, orange 0%, yellow 100%);
      box-shadow: 0 0 80px orange;
    }
    100% {
      background: linear-gradient(45deg, grey 0%, lightgrey 100%);
      box-shadow: -2px 2px 3px rgba(black, 0.3);
    }
  }

  @keyframes win2 {
    0% {
      background: linear-gradient(45deg, orange 0%, yellow 100%);
      box-shadow: 0 0 80px orange;
    }
    100% {
      background: linear-gradient(45deg, grey 0%, lightgrey 100%);
      box-shadow: -2px 2px 3px rgba(black, 0.3);
    }
  }
  @keyframes win3 {
    0% {
      background: linear-gradient(
        45deg,
        rgb(78, 255, 96) 0%,
        rgb(45, 255, 45) 100%
      );
      box-shadow: 0 0 80px rgb(42, 175, 42);
    }
    100% {
      background: linear-gradient(45deg, grey 0%, lightgrey 100%);
      box-shadow: -2px 2px 3px rgba(black, 0.3);
    }
  }

  &.win1 {
    animation: win1 200ms steps(2, end) infinite;
  }

  &.win2 {
    animation: win2 200ms steps(2, end) infinite;
  }

  &.win3 {
    animation: win3 200ms steps(2, end) infinite;
  }

  .reel {
    position: relative;
    width: $icon_width;
    height: 3 * $icon_height;
    border: 1px solid rgba(black, 0.3);
    overflow: hidden;
    background-image: url("../assets/img/gaming/slotreel.png");
    background-position: 0 0;
    background-repeat: repeat-y;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        rgba(black, 0.4) 0%,
        transparent 30%,
        transparent 70%,
        rgba(black, 0.4) 100%
      );
      box-shadow: inset 0 0 6px 2px rgba(black, 0.3);
    }
  }
}

.nl-rotating-text {
  display: block;
  width: 315px;
  overflow: hidden;
  text-wrap: nowrap;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 0%,
      transparent 30%,
      transparent 70%,
      rgba(0, 0, 0, 1) 100%
    );
  }
  span {
    display: block;
    line-height: 20px;
  }
}

#scroll-container {
  border-radius: 5px;
  overflow: hidden;
}

#scroll-text {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-110%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-110%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-110%);
    -webkit-transform: translateX(-110%);
    transform: translateX(-110%);
  }
}

@media only screen and (max-width: 767px) {
  .slots {
    width: 3 * $icon_width_mobile;
    height: 3 * $icon_height_mobile;
    .reel {
      width: $icon_width_mobile;
      height: 3 * $icon_height_mobile;
      background-image: url("../assets/img/gaming/slotreel-mobile.png");
    }
  }
  .nl-rotating-text {
    width: 201px;
    height: 20px;
    line-height: 18px;
    font-size: 12px;
  }

  /* for Firefox */
  @-moz-keyframes my-animation {
    // from {
    //   -moz-transform: translateX(100%);
    // }
    to {
      -moz-transform: translateX(-145%);
    }
  }

  /* for Chrome */
  @-webkit-keyframes my-animation {
    // from {
    //   -webkit-transform: translateX(100%);
    // }
    to {
      -webkit-transform: translateX(-145%);
    }
  }

  @keyframes my-animation {
    // from {
    //   -moz-transform: translateX(100%);
    //   -webkit-transform: translateX(100%);
    //   transform: translateX(100%);
    // }
    to {
      -moz-transform: translateX(-145%);
      -webkit-transform: translateX(-145%);
      transform: translateX(-145%);
    }
  }
}
