.shopping-section {
  background-color: $color-shopping-gray;
  padding: 150px 0;
}

.deso-rate {
  display: inline-block;
  position: fixed;
  right: 15px;
  top: 50px;
  z-index: 999;
}

.shopping-spinner-wrapper {
  .spinner-border {
    border-color: $color-2-purple;
    border-right-color: transparent;
    border-left-color: transparent;
    border-style: dotted;
    width: 50px;
    height: 50px;
  }
}

// pagination start

.pagination-text {
  font-size: 14px;
  line-height: 28px;
}

.pagination {
  &.text-right {
    text-align: right;
  }

  .page-item {
    text-align: center;

    & + .page-item {
      margin-left: 0.5rem;
    }

    a {
      color: $color-2-purple-lt;
      border-radius: 4px;
      border-color: $color-2-purple-lt;
      background-color: transparent;
      font-size: 12px;
      line-height: 16px;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;

      &.active {
        border-color: $color-2-purple;
        color: $color-2-purple;
      }

      .arrow {
        font-size: 12px;
        line-height: 12px;
      }
    }

    &:first-child a,
    &:last-child a {
      @extend .material-symbols;
      color: $color-2-purple-lt;
      cursor: pointer;
    }

    &:first-child a,
    &:last-child a {
      &::before {
        position: absolute;
        font-size: 15px;
        line-height: 15px;
        width: 100%;
      }
    }
    &:first-child a {
      &::before {
        content: "\e2ea";
      }
    }
    &:last-child a {
      &::before {
        content: "\e5e1";
      }
    }
  }
}

// pagination resp start

@media only screen and (max-width: 767px) {
  .pagination-text {
    font-size: 12px;
    line-height: 16px;
  }
  .pagination {
    .page-item {
      a {
        font-size: 10px;
        line-height: 14px;
        width: 18px;
        height: 18px;

        .arrow {
          font-size: 10px;
          line-height: 10px;
        }
      }
    }
  }
}
// pagination end

/* start of button rules */

button.prod-cart {
  padding: 0.1em 0.25em;
  width: 130px;
  height: 48px;
  background-color: #5a5a5a;
  border: 0.08em solid #fff;
  border-radius: 0.3em 0.3em 20px 0.3em;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  &::after {
    content: attr(data-text);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0.5em;
    width: 123px;
    height: 2.5em;
    background-color: $btn-grad-2;
    border-radius: 0.2em 0.2em 20px 0.2em;
    font-size: 1.5em;
    color: #fff;
    border: 0.08em solid #cfd7ff;
    box-shadow: 0 0.4em 0.1em 0.019em #5e79ff;
  }
  &:hover {
    &::after {
      transition: all 0.5s;
      transform: translate(0, 0.4em);
      box-shadow: 0 0 0 0 #5e79ff;
    }
  }
  &:not(hover) {
    &::after {
      transition: all 1s;
    }
  }

  &.variant-1 {
    &::after {
      background-color: $color-1-blue;
      border: 0.08em solid #6ff1ff;
      box-shadow: 0 0.4em 0.1em 0.019em #08717c;
    }
    &:hover {
      &::after {
        box-shadow: 0 0 0 0 #08717c;
      }
    }
  }
  &.variant-2 {
    &::after {
      background-color: $color-2-purple;
      border: 0.08em solid #ddb7ff;
      box-shadow: 0 0.4em 0.1em 0.019em #6b25a8;
    }
    &:hover {
      &::after {
        box-shadow: 0 0 0 0 #6b25a8;
      }
    }
  }
  &.variant-3 {
    &::after {
      background-color: $color-4-yellow;
      border: 0.08em solid #ffde77;
      box-shadow: 0 0.4em 0.1em 0.019em #b68b04;
    }
    &:hover {
      &::after {
        box-shadow: 0 0 0 0 #b68b04;
      }
    }
  }
  &.variant-4 {
    &::after {
      background-color: $color-5-red;
      border: 0.08em solid #f5757b;
      box-shadow: 0 0.4em 0.1em 0.019em #620409;
    }
    &:hover {
      &::after {
        box-shadow: 0 0 0 0 #620409;
      }
    }
  }

  &.variant-5 {
    &::after {
      background-color: $color-7-orange;
      border: 0.08em solid #ffd29b;
      box-shadow: 0 0.4em 0.1em 0.019em #7b4606;
    }
    &:hover {
      &::after {
        box-shadow: 0 0 0 0 #7b4606;
      }
    }
  }
  &.variant-added {
    &::after {
      background-color: $color-3-green !important;
      border: 0.08em solid #d6ffbf !important;
      box-shadow: 0 0.4em 0.1em 0.019em #4a7f2b !important;
    }
    &:hover {
      &::after {
        box-shadow: 0 0 0 0 #4a7f2b !important;
      }
    }
  }
  &.disabled {
    pointer-events: none;
  }
}

/* end of button rules */

.shopping-menu-col {
  display: flex;
  align-items: center;
  position: relative;
  > span {
    display: inline-block;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    padding: 0px 15px;
    line-height: 48px;
    cursor: pointer;
    @extend .transition-all;
    margin-right: 20px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      right: 0px;
      height: 38px;
      width: 38px;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1),
        2px 2px 2px rgba(201, 217, 232, 0.3);
      border-bottom-right-radius: 15px;
      pointer-events: none;
      @extend .transition-all;
    }
    &:hover,
    &.active {
      color: $color-2-purple;
      &::after {
        box-shadow: 2px 2px 3px $color-2-purple-lt,
          3px 3px 3px rgba(201, 217, 232, 0.3);
        // border-bottom-right-radius: 15px;
      }
    }
  }
  .search-icon {
    display: block;
    width: 48px;
    height: 48px;
    cursor: pointer;
    padding: 9px;
    margin-left: 10px;
    border: none;
    outline: none;
    background-color: $color-tr;
    @extend .transition-all;
    > span {
      font-size: 30px;
    }
  }
  .search-block {
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 48px;
    right: 0;
    padding: 0 10px;
    .form-control {
      min-height: 42px;
      width: 100%;
      color: $color-main-bg;
      outline: none;
      transition: 0.35s;
      padding: 0px 12px;
      background-color: $color-white;
      border-radius: 30px;
      border: 2px solid $color-white;
      box-shadow: 3px 3px 5px rgba(119, 119, 119, 1),
        1px 1px 5px rgba(255, 255, 255, 0.6);
    }

    .form-control::placeholder {
      color: #999;
    }

    .form-control:focus.form-control::placeholder {
      transition: 0.3s;
      opacity: 0;
    }

    .form-control:focus {
      transform: scale(1.05);
      box-shadow: 3px 3px 5px rgb(119 119 119),
        1px 1px 5px rgba(255, 255, 255, 0.6), inset 2px 2px 5px rgb(119 119 119),
        inset -1px -1px 5px rgba(255, 255, 255, 0.6);
    }
  }
  .search-result-block {
    position: absolute;
    overflow-y: auto;
    z-index: 999;
    width: calc(100% - 20px);
    min-height: 120px;
    height: auto;
    right: 10px;
    top: 105px;
    padding: 15px;
    color: $color-main-bg;
    background-color: $color-white;
    border-radius: 20px;
    border: 2px solid $color-white;
    box-shadow: 3px 3px 5px rgba(119, 119, 119, 1),
      1px 1px 5px rgba(255, 255, 255, 0.6);
    .search-head {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 5px;
      color: $color-main-bg;
      position: relative;
      height: 38px;
      line-height: 38px;
      padding: 0 15px;
      color: $color-2-purple;
      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: 32px;
        width: 38px;
        box-shadow: -1px 1px 2px $color-2-purple-lt,
          -2px 2px 2px rgba(201, 217, 232, 0.3);
        border-bottom-left-radius: 15px;
        pointer-events: none;
      }
    }
    .search-list {
      margin-bottom: 15px;
      span {
        display: block;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        padding: 5px 10px;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        cursor: pointer;
        @extend .transition-all;
        &:hover {
          color: $color-2-purple;
        }
      }
    }
  }
  .cart-btn {
    display: inline-block;
    display: flex;
    height: 100%;
    align-items: center;
    height: 48px;
    width: 48px;
    padding: 5px;
    position: relative;
    &::after {
      content: attr(data-num);
      position: absolute;
      display: block;
      text-align: center;
      width: 20px;
      height: 20px;
      padding: 2px;
      font-size: 10px;
      line-height: 14px;
      border-radius: 50%;
      top: 0px;
      right: 0px;
      background-color: $color-5-red;
      color: $color-white;
      border: 1px solid $color-white;
      box-shadow: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  .shopping-menu-col {
    margin-bottom: 20px;
    > span {
      font-size: 15px;
      font-weight: 500;
      line-height: 40px;
    }
    .search-icon {
      width: 40px;
      height: 40px;
      padding: 7px;
      > span {
        font-size: 26px;
      }
    }
  }
}

.category-sort-row {
  margin-top: 30px;
  .category-head {
    display: block;
    width: 100%;
    font-size: 26px;
    font-weight: 700;
    span {
      color: $color-2-purple;
    }
  }

  .sort-col {
    position: relative;
    margin-bottom: 20px;
    .sort-icon {
      display: block;
      width: 39px;
      height: 39px;
      cursor: pointer;
      padding: 3px;
      margin-left: 10px;
      border: none;
      outline: none;
      background-color: $color-tr;
      @extend .transition-all;
      > span {
        font-size: 33px;
      }
      &:hover {
        color: $color-2-purple;
      }
    }
    .sort-list {
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 20px;
      z-index: 9;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 10px;
      border-radius: 15px;
      span {
        display: inline-block;
        cursor: pointer;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .category-sort-row {
    .category-head,
    .sort-head {
      font-size: 22px;
    }
    .sort-head {
      margin-top: 20px;
    }
    .sort-list {
      span {
        font-size: 16px;
      }
    }
  }
}

.product-listing-main-wrapper-row {
  margin-top: 40px;

  .prodlist-main-wrapper {
    .product-wrapper {
      width: 23.5%;
      max-width: 340px;
      display: inline-block;
      margin-right: 2%;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 30px;
      &:nth-child(4n + 4) {
        margin-right: 0;
      }
      .img-container {
        width: 100%;
        height: auto;
        cursor: pointer;
        overflow: hidden;
        display: block;
        // picture {
        //   object-fit: cover;
        //   background-color: $color-shopping-gray;
        //   width: 100%;
        //   height: 100%;
        //   display: flex;
        //   align-items: center;
        //   overflow: hidden;
        //   img {
        //     max-height: 100%;
        //     max-width: 100%;
        //     display: block;
        //     margin: auto;
        //     @extend .transition-all;
        //   }
        // }
      }
      // &:hover .img-container {
      //   picture {
      //     img {
      //       transform: scale(1.05);
      //     }
      //   }
      // }
      .product-info {
        display: block;
        width: 100%;
        position: relative;
        border-radius: 0px 0px 30px 30px;
        padding: 0px;
        padding-top: 15px;
        background-color: $color-white;

        .prod-title {
          font-size: 20px;
          color: $color-black;
          font-weight: 500;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-height: 26px;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 26px;
          padding: 0 10px;
          margin-bottom: 14px;
          cursor: pointer;
        }
        // .prod-sub-title {
        //   font-size: 12px;
        //   color: #707070;
        //   text-overflow: ellipsis;
        //   display: -webkit-box;
        //   -webkit-line-clamp: 1;
        //   line-height: 14px;
        //   -webkit-box-orient: vertical;
        //   overflow: hidden;
        //   height: 14px;
        //   margin-bottom: 8px;
        //   padding: 0 10px;
        // }
        .prod-price-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 12px 10px;
          height: 58px;

          img {
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 1px;
            margin-top: -3px;
          }
          .prod-price {
            display: inline-block;
            font-size: 16px;
            line-height: 48px;
            margin-right: 10px;
          }

          .prod-price-dollar {
            display: inline-block;
            font-size: 15px;
            line-height: 48px;
            color: #707070;
          }
        }

        .gallery-icon-wrap {
          width: 40px;
          height: 40px;
          position: absolute;
          top: -30px;
          right: 10px;
          background-color: $color-main-bg;
          border-radius: 10px;
          text-align: center;
          padding: 6px 9px 9px;
          cursor: pointer;
          .material-symbols-outlined {
            width: 22px;
            height: 22px;
            color: $color-white;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .product-listing-main-wrapper-row {
    .prodlist-main-wrapper {
      .product-wrapper {
        width: 31.33%;

        &:nth-child(4n + 4) {
          margin-right: 2%;
        }
        &:nth-child(3n + 3) {
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .product-listing-main-wrapper-row {
    .prodlist-main-wrapper {
      .product-wrapper {
        width: 49%;
        &:nth-child(3n + 3) {
          margin-right: 2%;
        }
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .product-listing-main-wrapper-row {
    .prodlist-main-wrapper {
      .product-wrapper {
        width: 100%;
        display: block;
        max-width: 340px;
        margin: 0 auto 30px;
        &:nth-child(3n + 3) {
          margin-right: auto;
        }
        &:nth-child(2n + 2) {
          margin-right: auto;
        }
        .product-info {
          .prod-title {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.offcanvas-backdrop {
  background-color: $color-main-bg;
  &.show {
    opacity: 0.7;
  }
}

.shopping-offcanvas {
  background-color: $color-tr;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  .btn-close {
    background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
    padding: 0.25em 0.25em;
    opacity: 1;
    &:focus {
      box-shadow: none;
    }
  }
  .offcanvas-title {
    min-height: 30px;
  }
  .offcanvas-body {
    padding-top: 0;
    overflow: hidden;
  }
}

.shopping-tab-section {
  background-color: $color-tr;
  height: 100%;
  .nav-tabs {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid $color-tr;
    width: 100%;
    .nav-item {
      width: 50%;
    }
    button {
      width: 100%;
      padding: 17px 18px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      text-transform: lowercase;
      color: $color-white;
      background: $color-tr;
      border: none;
      border-bottom: 1px solid $color-tr;

      &.active,
      &:focus,
      &:hover {
        color: $color-2-purple;
        background: $color-tr;
        border: none;
        border-bottom: 1px solid $color-2-purple;
      }
    }
  }

  .tab-content {
    overflow-y: auto;
    padding: 0px 20px 15px;
    color: $color-white;
    box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 1),
      inset -1px -1px 5px rgba(255, 255, 255, 0.6);
    border-radius: 20px;
    height: calc(100% - 80px);
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-2-purple-lt;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: $color-2-purple;
      border-radius: 5px;
    }
  }
  .tab-pane {
    position: relative;
    padding: 20px 0px;
    z-index: 1;
  }
}

@media only screen and (max-width: 575px) {
  .shopping-tab-section {
    .nav-tabs {
      button {
        font-size: 16px;
      }
    }
  }
}

.shopping-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    display: block;
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    span {
      color: $color-white;
      display: block;
      width: 100%;
      font-size: 18px;
      line-height: 28px;
      padding: 5px 0px 5px 15px;
      font-weight: 400;
      cursor: pointer;
      border-bottom: 1px solid $color-tr;
      @extend .transition-all;

      &.main-head {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        padding: 5px 0px;
      }
      &.head {
        font-weight: 600;
        padding: 5px 0px;
      }
      &:hover,
      &.active {
        border-bottom: 1px solid $color-2-purple-lt;
      }
    }
  }
}

.brands-list-wrapper {
  overflow-y: auto;
  padding: 15px 20px;
  color: $color-white;
  box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 1),
    inset -1px -1px 5px rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  height: calc(100% - 20px);
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-2-purple-lt;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-2-purple;
    border-radius: 5px;
  }
}

.pagination-col {
  button {
    padding: 0.1em 0.25em;
    width: 40px;
    height: 40px;
    background-color: #929292;
    border: 0.08em solid #fff;
    border-radius: 50%;
    font-size: 12px;
    cursor: pointer;
    > span {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0.4em;
      width: 33px;
      height: 33px;
      background-color: $btn-grad-2;
      border-radius: 50%;
      color: #fff;
      border: 0.08em solid #cfd7ff;
      box-shadow: 0 0.4em 0.1em 0.019em #5e79ff;
      &.disabledBtn {
        background-color: #818181;
        box-shadow: 0 0.4em 0.1em 0.019em #494949;
        &:hover {
          transition: all 0.5s;
          transform: translate(0);
          box-shadow: 0 0.4em 0.1em 0.019em #494949;
        }
      }
      &:hover {
        transition: all 0.5s;
        transform: translate(0, 0.4em);
        box-shadow: 0 0 0 0 #5e79ff;
      }
      &:not(hover) {
        transition: all 1s;
      }
      > span {
        font-size: 18px;
      }
    }
  }
}

/* start of product details rules */

.product-details-row {
  padding-top: 80px;
  padding-bottom: 80px;

  .product-gallery-wrapper {
    background-color: $color-white;
    border-radius: 40px;
    padding: 20px;
    box-shadow: rgba(200, 200, 200, 0.8) 3px 3px 6px 0px inset,
      rgba(200, 200, 200, 0.8) -3px -3px 6px 1px inset;
    .main-image {
      max-width: 480px;
      margin: 0 auto 30px;
      border-radius: 20px;
      overflow: hidden;
      picture {
        border-radius: 20px;
        overflow: hidden;
      }
    }
    .thumb-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      overflow-x: auto;
      padding: 12px;
      .img-thumb {
        padding: 5px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 10px;
        border-radius: 20px;
        cursor: pointer;
        overflow: hidden;
        min-width: calc(25% - 5px);
        max-width: 150px;
        picture {
          display: block;
          margin: 0 auto;
          border-radius: 15px;
          overflow: hidden;
        }
      }
    }
  }

  .product-details-wrapper {
    .product-name {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 20px;
      color: $color-2-purple;
    }
    .product-price {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      img {
        width: 30px;
        height: 30px;
      }
      .prod-price {
        font-size: 30px;
        line-height: 40px;
        font-weight: 600;
      }
    }
  }
}

.shopping-tab-section.product-details-tab-section {
  min-height: 250px;
  max-height: 470px;
  .nav-tabs {
    button {
      color: $color-main-bg;
      background: $color-tr;
      border-bottom: none;
      &.active,
      &:focus,
      &:hover {
        color: $color-2-purple;
        background: $color-tr;
        border: none;
        border-bottom: none;
      }
    }
  }

  .tab-content {
    overflow-y: auto;
    padding: 0px 20px 15px;
    background-color: $color-white;
    color: $color-black;
    box-shadow: rgba(200, 200, 200, 0.8) 3px 3px 6px 0px inset,
      rgba(200, 200, 200, 0.8) -3px -3px 6px 1px inset;
    border-radius: 20px;
    max-height: 400px;
    &::-webkit-scrollbar-thumb {
      background: $color-2-purple;
    }

    &::-webkit-scrollbar-track {
      background-color: $color-2-purple-lt;
    }
  }

  .details-tab-content {
    font-size: 16px !important;
    line-height: 24px;
  }
}

/* end of product details rules */

/* start cart page */

.cart-details-row {
  padding-top: 50px;
  padding-bottom: 50px;
  .no-products-cart {
    display: block;
    font-weight: 600;
    font-size: 20px;
    color: $color-5-red;
  }
  .cart-head {
    display: block;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .cart-products-wrapper {
    background-color: $color-white;
    border-radius: 30px;
    padding: 20px;
    box-shadow: rgba(200, 200, 200, 0.8) 3px 3px 6px 0px inset,
      rgba(200, 200, 200, 0.8) -3px -3px 6px 1px inset;
    .delivery-details {
      margin-bottom: 20px;
      align-items: center;
      .delivery-by {
        font-size: 20px;
        font-weight: 600;
      }
    }
    .cart-product-item {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 3px;
      border-radius: 15px;
      margin-bottom: 20px;
      position: relative;
      padding: 10px;
      picture {
        display: inline-block;
        border-radius: 15px;
        overflow: hidden;
        padding: 10px;
        margin-bottom: 10px;
        max-width: 200px;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
          rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
          rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
        img {
          border-radius: 15px;
        }
      }

      .product-title {
        display: block;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        margin-bottom: 20px;
        color: $color-gBlack4;
      }
      .product-price {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        img {
          width: 26px;
          height: 26px;
          opacity: 0.7;
        }
        .prod-price {
          font-size: 30px;
          line-height: 40px;
          font-weight: 600;
          color: $color-2-purple;
        }
      }

      .cart-icons-block {
        button {
          padding: 0.1em 0.25em;
          width: 40px;
          height: 40px;
          background-color: #4f4f4f;
          border: 0.08em solid #fff;
          border-radius: 50%;
          font-size: 12px;
          cursor: pointer;
          > span {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 0.4em;
            width: 33px;
            height: 33px;
            background-color: $btn-grad-2;
            border-radius: 50%;
            color: #fff;
            border: 0.08em solid #cfd7ff;
            box-shadow: 0 0.4em 0.1em 0.019em #5e79ff;
            &.disabledBtn {
              background-color: #818181;
              box-shadow: 0 0.4em 0.1em 0.019em #494949;
              &:hover {
                transition: all 0.5s;
                transform: translate(0);
                box-shadow: 0 0.4em 0.1em 0.019em #494949;
              }
            }
            &:hover {
              transition: all 0.5s;
              transform: translate(0, 0.4em);
              box-shadow: 0 0 0 0 #5e79ff;
            }
            &:not(hover) {
              transition: all 1s;
            }
            > span {
              font-size: 18px;
            }
          }
        }
      }

      .cart-delete-icon {
        display: block;
        width: 48px;
        height: 48px;
        position: absolute;
        bottom: 0px;
        right: 10px;
        button {
          > span {
            background-color: $color-5-red;
            border: 0.08em solid #f5757b;
            box-shadow: 0 0.4em 0.1em 0.019em #620409;
            &:hover {
              box-shadow: 0 0 0 0 #620409;
            }
          }
        }
      }
      .quantity-wrapper {
        display: inline-block;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
          rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
          rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
        padding: 8px 10px;
        border-radius: 30px;
        width: auto;
        margin-bottom: 10px;
        input {
          border: none;
          width: 80px;
          min-height: 38px;
          text-align: center;
          font-weight: 500;
          font-size: 20px;
          &:active,
          &:focus,
          &:focus-visible {
            border: none;
            box-shadow: none;
            outline: none;
          }
        }
      }
      &:last-child {
        margin-bottom: 5px;
      }
    }
  }

  .cart-pricing-details {
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 30px;
    padding: 20px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      right: 0px;
      height: 130px;
      width: 130px;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1),
        10px 10px 5px rgba(201, 217, 232, 0.3);
      border-bottom-right-radius: 20px;
      pointer-events: none;
    }
    .price-details-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $color-gBlack3;
      margin-bottom: 20px;
      padding-bottom: 10px;
      .use-balance-blk {
        width: 50px;
        height: auto;
        border-radius: 0px 20px 0px 0px;
        padding: 8px 8px 5px;
        right: 0px;
        top: 140px;
        text-align: center;
        cursor: pointer;

        @extend .transition-all;
        .img-blk {
          display: block;
          margin: 0 auto;
          width: 30px;
          height: 30px;
        }
        .text-blk {
          text-align: center;
          display: block;
          font-size: 16px;
          line-height: 20px;
          color: $color-2-purple;
          margin-top: 2;
          font-family: "Anta", sans-serif;
          font-weight: 400;
          font-style: normal;
        }
      }
      .price-details-head {
        display: block;
        font-size: 22px;
        font-weight: 600;
      }
    }
    .charges-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      .charge-prop {
        font-size: 18px;
        font-weight: 600;
        .img-blk {
          display: block;
          margin: 0 auto;
          width: 30px;
          height: 30px;
        }
      }
      .charge-value {
        font-size: 18px;
        font-weight: 700;
      }
    }
    .total-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
        rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
      padding: 10px 15px;
      border-radius: 30px;
      margin-top: 25px;
      position: relative;
      .total-text {
        font-size: 26px;
        font-weight: 600;
      }
      .total-value {
        font-size: 24px;
        font-weight: 700;
        color: $color-2-purple;
      }
      .balance-amount {
        position: absolute;
        right: 20px;
        top: -15px;
        background-color: $color-white;
        padding: 0px 8px;
        border: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 3px;
        border-radius: 10px;
        .img-blk {
          display: block;
          margin: 0 auto;
          width: 18px;
          height: 20px;
          img {
            margin-top: -8px;
          }
        }
        .text-blk {
          display: block;
          font-size: 15px;
          line-height: 20px;
          height: 20px;
          color: $color-2-purple;
          font-family: "Anta", sans-serif;
          font-weight: 400;
          font-style: normal;
        }
      }
    }
  }
  .cart-checkout-btn-wrapper {
    margin-top: 30px;
    button {
      border-radius: 0.3em 0.3em 1.8em 1.8em;
      height: 60px;
      width: 100%;

      &::after {
        border-radius: 0.2em 0.2em 1em 1em;
        width: calc(100% - 6px);
        height: 56px;
        font-size: 22px;
        background-color: $color-2-purple;
        border: 0.08em solid #ddb7ff;
        box-shadow: 0 0.4em 0.1em 0.019em #6b25a8;
      }
      &:hover {
        &::after {
          box-shadow: 0 0 0 0 #6b25a8;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .cart-details-row {
    .cart-products-wrapper {
      .cart-product-item {
        .quantity-wrapper {
          margin-bottom: 50px;
        }
        .product-title {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
  }
}

.checkout-details-wrapper {
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  margin-top: 30px;
  .checkout-details-head {
    display: block;
    font-size: 22px;
    font-weight: 600;
    padding: 15px 20px 0px;
  }
  .accordion {
    margin-top: 10px;
    --bs-accordion-border-radius: 20px;
    --bs-accordion-inner-border-radius: 20px;
  }
  .accordion-item {
    background-color: $color-tr;
    position: relative;
    border: none;
    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      right: 0px;
      height: 40px;
      width: 40px;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1),
        10px 10px 5px rgba(201, 217, 232, 0.3);
      border-bottom-right-radius: 20px;
      pointer-events: none;
    }
  }
  .accordion-button {
    flex-wrap: wrap;
    background-color: $color-tr;
    .header-wraper-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 40px);
    }
    .main-text,
    .payment-text {
      display: block;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 5px;
      padding-right: 40px;
      width: 100%;
    }
    .payment-text {
      display: inline-block;
      padding-right: 0;
      width: auto;
    }
    .add-address {
      -webkit-box-orient: vertical;
      display: -webkit-box !important;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      padding-right: 40px;
    }
    &::after {
      position: absolute;
      right: 20px;
    }
    &:focus {
      box-shadow: none;
    }
    &:not(.collapsed) {
      background-color: $color-2-purple;
      color: $color-white;
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius);
      &:after {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
      }
    }
  }
  // .address-main-wrapper {
  //   max-height: 460px;
  //   overflow-y: auto;
  //   padding: 20px 0px;
  // }
  .cart-address-block {
    display: flex;
    border-bottom: 1px dotted $color-2-purple-lt;
    padding-bottom: 15px;
    margin-bottom: 15px;
    .btn-wrapper {
      width: 36px;
      height: 36px;
      button {
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
          rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
          rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
        border: none;
        background-color: $color-white;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        padding: 4px 6px 8px;
        @extend .transition-all;
        &.default,
        &:hover {
          background-color: $color-3-green;
          color: $color-white;
        }
      }
    }
    .address-wrapper {
      padding-right: 15px;
      .add-name {
        font-weight: 600;
        font-size: 18px;
      }
      // .add-address {
      // }
      .add-phone {
        font-weight: 600;
      }
    }
  }
  .cart-address-block + button {
    width: 100%;
    border-radius: 0.3em;
    &::after {
      width: calc(100% - 6px);
      border-radius: 0.2em;
    }
  }

  /* payment modes blocks */

  .payment-mode-wrapper {
    border-bottom: 1px dotted $color-2-purple-lt;
    margin-bottom: 20px;
    .mode-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      .head-text {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
      }
      button {
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
          rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
          rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
        border: none;
        background-color: $color-white;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        padding: 4px 6px 8px;
        @extend .transition-all;
        &.default,
        &:hover {
          background-color: $color-2-purple;
          color: $color-white;
        }
      }
    }
    .mode-info-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      cursor: pointer;
      .mode-logo {
        display: inline-block;
        padding: 5px;
        max-width: 120px;
        max-height: 80px;
        margin-right: 10px;
        img {
          display: block;
          margin: 0 auto;
        }
        &.wallets {
          max-width: 50px;
          max-height: 50px;
          width: 50px;
          height: 50px;
        }
      }
      .mode-name {
        font-size: 16px;
        font-weight: 500;
      }
      .mode-creds {
        font-size: 18px;
        font-weight: 600;
      }
      &.default {
        .mode-logo {
          border: 1px solid $color-2-purple;
          border-radius: 8px;
        }
        .mode-name {
          color: $color-2-purple;
        }
        .mode-creds {
          color: $color-2-purple;
        }
      }
      .wallet-info-wrapper {
        display: flex;
        align-items: center;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
    &:last-child {
      border-bottom: none;
      margin-bottom: 0px;
    }
  }

  .currency-info-wrapper {
    .currency-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      cursor: pointer;
      .currency-logo {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        img {
          display: block;
          margin: 0 auto;
        }
      }
      .currency-name {
        font-size: 18px;
        font-weight: 600;
      }
      &.default {
        .currency-logo {
          border: 1px solid $color-2-purple;
          padding: 4px;
          border-radius: 50%;
        }
        .currency-name {
          color: $color-2-purple;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/* end cart page */
