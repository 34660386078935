@import "../assets/abstracts/variables";
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined");

.overlay-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/checkout-bg.jpg");
  background-repeat: repeat-y;
  background-size: contain;
  z-index: -1;
}

.overlay-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
}

img {
  max-width: 100%;
}

.main-checkout-wrapper {
  display: flex;
  padding-top: 150px;
  justify-content: center;
  .main-checkout-window {
    border: 2px solid $btn-grad-tr-1;
    border-radius: 20px;
    background-color: $color-main-bg-tr;
    min-height: 400px;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    position: absolute;
    top: 150px;
    z-index: 1;
    overflow: hidden;
  }

  .main-brand-section {
    position: relative;
    width: 100%;
    border-bottom: 1px solid $color-black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    h3 {
      font-size: 20px;
      color: $color-white;
      line-height: 30px;
    }
    .close-icon {
      display: block;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      color: $color-white;
      cursor: pointer;
      span {
        margin: 3px;
      }
    }
    &.footer {
      border-bottom: none;
      border-top: 1px solid $color-black;
      margin-top: 10px;
      h3 {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: $color-white;
        line-height: 20px;
        font-weight: 300;
      }
    }
  }

  .use-balance-blk {
    position: absolute;
    width: 50px;
    height: auto;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 20px 0px 0px 20px;
    padding: 8px;
    right: 0px;
    top: 140px;
    text-align: center;
    color: $color-white;
    cursor: pointer;
    .img-blk {
      display: block;
      margin: 0 auto;
      width: 30px;
      height: 30px;
    }
    .text-blk {
      text-align: center;
      display: block;
      font-size: 16px;
      line-height: 20px;
      color: $color-white;
      margin-top: 2;
      font-family: "Anta", sans-serif;
      font-weight: 400;
      font-style: normal;
    }
    &.active {
      border: 1px solid $color-3-green;
    }
  }

  header.checkout-header {
    .merchant-logo {
      width: 100px;
      height: 100px;
      border-radius: 20px;
      margin: 20px auto 0px;
      img {
        border-radius: 20px;
      }
    }
    h3 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      display: block;
      padding: 15px;
      color: $color-white;
    }
  }

  .merchant-info {
    text-align: center;
    span {
      display: block;
      color: $color-white;
    }
    .name {
      color: $color-white;
      font-weight: 600;
      font-size: 18px;
      margin-top: 5px;
    }
    .amount-text {
      font-size: 16px;
      margin-top: 5px;
    }
    .amount {
      font-size: 20px;
      margin-top: 5px;
      font-weight: 600;
    }
    .balance-amount {
      margin-top: 5px;
      .img-blk {
        display: inline-block;
        margin: 0 auto;
        width: 24px;
        height: 24px;
      }
      .text-blk {
        display: inline-block;
        font-size: 16px;
        line-height: 20px;
        color: $color-white;
        margin-top: 2;
        font-family: "Anta", sans-serif;
        font-weight: 400;
        font-style: normal;
      }
    }
  }

  .main-checkout-wrapper {
    .main-form-group {
      .form-control {
        width: 100%;
        min-height: 45px;
        color: $color-white;
        outline: none;
        transition: 0.35s;
        padding: 0px 12px;
        background-color: $color-main-bg;
        border-radius: 30px;
        border: 2px solid $color-main-bg;
        box-shadow: 6px 6px 10px rgba(0, 0, 0, 1),
          1px 1px 10px rgba(255, 255, 255, 0.6);
      }

      .form-control::placeholder {
        color: #999;
      }

      .form-control:focus.form-control::placeholder {
        transition: 0.3s;
        opacity: 0;
      }

      .form-control:focus {
        transform: scale(1.05);
        box-shadow: 6px 6px 10px rgba(0, 0, 0, 1),
          1px 1px 10px rgba(255, 255, 255, 0.6),
          inset 2px 2px 10px rgba(0, 0, 0, 1),
          inset -1px -1px 5px rgba(255, 255, 255, 0.6);
      }
    }
  }

  .form-section {
    padding: 20px;
    border-top: 1px solid $color-black;
    margin-top: 20px;
    .form-header-text {
      font-size: 14px;
      margin-top: 5px;
      color: $color-white;
      margin-bottom: 8px;
    }

    .main-btn {
      margin-top: 10px;
      span {
        font-size: 16px;
        padding: 8px 20px;
      }
    }
  }

  .terms-screen {
    padding: 20px;
    border-top: 1px solid $color-black;
    margin-top: 20px;
    position: relative;
    .main-btn,
    .main-btn-wo-anim {
      span {
        font-size: 18px;
      }
    }

    .terms-heading {
      color: $color-white;
      text-align: center;
      span {
        display: block;
      }
      .main-head {
        font-weight: 700;
        font-size: 18px;
      }
      .sub-head {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
    .terms-btns {
      .main-btn,
      .main-btn-2,
      .main-btn-3 {
        width: 100%;
        margin-top: 10px;
        span {
          font-size: 16px;
        }
      }

      .marketing-opt-in {
        align-items: center;
        // background-color: $color-main-bg !important;
        // border: 2px solid $btn-grad-1;
        border-radius: 35px;
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        padding: 12px 20px;
        color: $color-white;
        .main-text {
          font-weight: 700;
          font-size: 18px;
          small {
            display: block;
            font-weight: 400;
          }
        }
        .form-check-input {
          width: 18px;
          height: 18px;
          &:checked {
            background-color: $color-3-green;
            border-color: $color-3-green;
          }
          &:focus {
            border-color: $color-3-green;
          }
        }
      }
    }

    .terms-screen-content {
      color: $color-white;
      height: 300px;
      max-height: 300px;
      width: 100%;
      position: absolute;
      padding: 20px;
      top: 0;
      left: 0;
      background: $color-main-bg;
      overflow-y: auto;
      p {
        color: $color-white;
        font-size: 14px;
        line-height: 22px;
      }
      h4 {
        display: block;
        color: $color-white;
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 5px;
      }
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: $btn-grad-1;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px #e0e0e0;
        background-color: $color-black;
        border-radius: 6px;
      }
    }
    .curved-close-icon {
      display: flex;
      width: 30px;
      height: 23px;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0;
      cursor: pointer;
      img {
        width: 30px;
        height: 23px;
        max-width: 100%;
      }
    }
  }

  .otp-screen {
    padding: 20px;
    border-top: 1px solid $color-black;
    margin-top: 20px;
    .otp-text {
      color: $color-white;
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 24px;
      &.bottom {
        span {
          display: block;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          margin-top: 10px;
          margin-bottom: 0;
          color: $btn-grad-1;
        }
      }
    }
    .otp-inputs {
      display: flex;
      flex-wrap: nowrap;
      gap: 10px;
      align-items: center;
      justify-content: center;
      .main-form-group {
        margin-bottom: 5px;
        .form-control {
          text-align: center;
        }
      }
    }
    .main-btn {
      margin-top: 10px;
      span {
        font-size: 16px;
        padding: 8px 20px;
      }
    }
  }

  .adding-screen {
    padding: 20px;
    border-top: 1px solid $color-black;
    margin-top: 20px;
    .main-btn {
      margin-top: 10px;
      span {
        font-size: 18px;
        padding: 8px 25px;
      }
    }

    .adding-text {
      color: $color-white;
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 24px;
    }

    .add-btns-wrapper {
      display: flex;
      justify-content: space-between;
      .main-btn {
        &:first-child {
          margin-right: 10px;
        }
      }
    }

    .wallet-bank-list-wrapper {
      border-top: 1px solid $btn-grad-tr-1;
      margin-top: 40px;
      padding: 20px 15px;
      .wallet-list {
        min-height: 250px;
        max-height: 300px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 3px;
          height: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background: $btn-grad-1;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-track {
          // box-shadow: inset 0 0 5px #e0e0e0;
          background-color: $color-black;
          border-radius: 6px;
        }
        .list-item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          border-bottom: 1px solid $color-main-bg;
          transition: all 0.3s ease-in-out;
          padding: 10px 0px;
          .list-item-img {
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 10px;
            overflow: hidden;
            img {
              max-width: 100%;
              margin: 0 auto;
              border-radius: 10px;
            }
          }
          .list-item-name {
            display: inline-block;
            padding-left: 20px;
            color: $color-white;
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
            transition: all 0.3s ease-in-out;
          }
          &:hover {
            border-bottom: 1px solid $btn-grad-1;
            .list-item-name {
              color: $btn-grad-1;
            }
          }
        }
        &.bank-list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: flex-start;
          .list-item-bank {
            display: block;
            width: 49%;
            border: 1px solid $color-main-bg-tr;
            padding: 5px;
            border-radius: 10px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            .list-item-bank-img {
              display: block;
              width: 100%;
              border-radius: 10px;
              overflow: hidden;
              img {
                max-width: 100%;
                margin: 0 auto;
                border-radius: 10px;
              }
            }
            &:hover {
              border: 1px solid $btn-grad-1;
            }
          }
        }
      }
    }
  }

  .main-checkout-screen {
    border-top: 1px solid $btn-grad-tr-1;
    margin-top: 20px;
    padding: 20px 15px;
    position: relative;
    .main-btn {
      margin: 10px auto 0;
      span {
        font-size: 18px;
        padding: 8px 25px;
      }
      &.dollarBg {
        background-color: $color-3-green !important;
      }
      &.desoBg {
        background: linear-gradient(
          to right,
          #0057ff 0%,
          #0296ff 50%,
          #01b0ff 100%
        );
      }
      &.solanaBg {
        background: linear-gradient(
          to right,
          #00ffa3 0%,
          #03e1ff 50%,
          #dc1fff 100%
        );
      }
      &.eRupeeBg {
        background: linear-gradient(to right, #333333 0%, #ff7400 100%);
      }
      &.bitcoinBg {
        background-color: #ff9416 !important;
      }
      &.rippleBg {
        background: linear-gradient(to right, #0090c9 0%, #0070a8 60%);
      }
      &.dodgeCoinBg {
        background: linear-gradient(
          to right,
          #7c6c2a 0%,
          #baa833 33%,
          #d8c173 64%,
          #e0cd81 100%
        );
      }
      &.commGrayBg {
        background: linear-gradient(
          to right,
          #c4c4c4 0%,
          #636363 60%,
          #505050 100%
        );
      }
    }
    .curved-close-icon {
      display: flex;
      width: 30px;
      height: 23px;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0;
      cursor: pointer;
      img {
        width: 30px;
        height: 23px;
        max-width: 100%;
      }
    }
    .selected-payment {
      margin-bottom: 30px;
      .selected-payment-icon-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start;
        margin-bottom: 3px;
        padding-bottom: 10px;
        height: 58px;
        &::-webkit-scrollbar {
          width: 3px;
          height: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background: $btn-grad-tr-1;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-track {
          // box-shadow: inset 0 0 5px #e0e0e0;
          background-color: $color-black;
          border-radius: 6px;
        }
        .selected-payment-icon {
          width: 50px;
          height: 50px;
          min-width: 50px;
          margin-right: 10px;
          border-radius: 50%;
          border: 2px solid $color-white;
          overflow: hidden;
          padding: 3px;
          cursor: pointer;
          img {
            max-width: 100%;
            border-radius: 50%;
          }
          &.active {
            border: 2px solid $btn-grad-1;
          }
        }
      }
      .available-payments-dropdown {
        .select {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 100%;
          height: 50px;
          background: transparent;
          color: $color-white;
        }

        .option {
          padding: 0 40px 0 15px;
          min-height: 50px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: $color-main-bg;
          border: 1px solid $btn-grad-tr-1;
          border-radius: 30px;
          position: absolute;
          top: 0;
          width: 100%;
          pointer-events: none;
          order: 2;
          z-index: 1;
          cursor: pointer;
          transition: all 0.1s ease-in-out;
          box-sizing: border-box;
          overflow: hidden;
          white-space: nowrap;
        }

        .option:hover {
          color: $btn-grad-1;
        }

        .select:focus .option {
          position: relative;
          pointer-events: all;
        }

        input {
          opacity: 0;
          position: absolute;
          left: -99999px;
        }

        input:checked + label {
          order: 1;
          z-index: 2;
          background: $color-main-bg;
          border: 1px solid $btn-grad-tr-1;
          position: relative;
        }

        input:checked + label:after {
          font-family: "Material Symbols Outlined";
          content: "\e5cf";
          width: 20px;
          height: 20px;
          color: $color-white;
          position: absolute;
          right: 15px;
          top: calc(50% - 12px);
          pointer-events: none;
          z-index: 3;
          font-size: 22px;
        }

        input:checked + label:before {
          position: absolute;
          right: 0;
          height: 30px;
          width: 30px;
          content: "";
        }
      }

      .add-new-payment {
        display: block;
        text-align: center;
        padding-right: 5px;
        font-size: 14px;
        color: $btn-grad-2;
        cursor: pointer;
        margin-top: 20px;
      }
    }

    .currency-select {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      justify-content: flex-start;
      margin-bottom: 15px;
      padding-bottom: 10px;
      height: 48px;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: $btn-grad-tr-1;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px #e0e0e0;
        background-color: $color-black;
        border-radius: 6px;
      }
      .curr-item {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        min-width: 40px;
        border-radius: 50%;
        border: 3px solid $btn-grad-1;
        background: rgba(255, 255, 255, 0.5);
        text-align: center;
        padding: 6px;
        margin-right: 15px;
        cursor: pointer;
        img {
          display: inline-block;
          max-width: 100%;
        }
        &.active {
          border: 3px solid $color-white;
        }
      }
    }
  }

  .payment-completed-screen {
    width: 100%;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    .payment-transfer-wrapper {
      position: relative;
      margin-top: 30px;
      margin-bottom: 30px;
      width: 90%;
      height: 300px;
      // border: 1px solid $color-white;
      .mt-merchant-icon {
        width: 70px;
        height: 70px;
        position: absolute;
        top: calc(50% - 35px);
        left: 0px;
        border: 1px solid $color-white;
        background-color: $color-main-bg;
        border-radius: 50%;
        padding: 5px;
        z-index: 2;
        font-weight: 600;
        &.r-icon {
          right: 0px;
          left: auto;
        }
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background-color: $color-white;
          border-radius: 50%;
          overflow: hidden;
          text-align: center;
          color: $color-main-bg;
          font-size: 30px;
          img {
            max-width: 100%;
            border-radius: 50%;
          }
        }
      }
      .payment-transfer-img {
        width: calc(100% - 40px);
        left: 20px;
        position: absolute;
        top: 26px;
        img {
          max-width: 100%;
        }
      }
      .payment-completed-img {
        width: 160px;
        height: 160px;
        // border: 2px solid $color-white;
        border-radius: 50%;
        padding: 10px;
        position: absolute;
        left: calc(50% - 80px);
        top: calc(50% - 90px);
        svg {
          max-width: 100%;
        }
      }
      .confetti-wrapper {
        width: 100%;
        // height: 410px;
        height: auto;
        margin: 0 auto;
        overflow: hidden;
        margin-top: -50px;
        img {
          max-width: 100%;
        }
      }
      .current-balances-wrapper {
        width: 100%;
        height: 40px;
        // background-color: #0070a8;
        position: absolute;
        top: -40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .curr-bal-block {
          // width: 33%;
          height: 40px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .img-blk {
            width: 38px;
            height: 38px;
            display: inline-block;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 20px 0px 0px 20px;
          }
          .text-blk {
            display: inline-block;
            font-size: 18px;
            line-height: 38px;
            color: $color-white;
            background-color: rgba(255, 255, 255, 0.1);
            padding: 0 15px 0px 5px;
            border-radius: 0px 15px 15px 0px;
            font-family: "Anta", sans-serif;
            font-weight: 400;
            font-style: normal;
          }
        }
      }
      .gift-received-wrapper {
        width: 100%;
        position: absolute;
        top: 35%;
        text-align: center;
        span {
          display: block;
          color: $color-white;
        }
        .gift-main-head {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 15px;
          font-weight: 500;
        }
        .gift-won {
          font-size: 40px;
          line-height: 42px;
          color: $color-3-green;
          font-family: "Anta", sans-serif;
          font-weight: 400;
          font-style: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 38px;
            height: 38px;
            display: inline-block;
            margin-right: 3px;
          }
          .voucher-brand {
            display: inline-block;
            width: 200px;
            height: 200px;
            border-radius: 20px;
            overflow: hidden;
            img {
              width: 200px;
              height: 200px;
              display: inline-block;
            }
          }
        }
      }
      .screen-redirection-text {
        position: absolute;
        top: 35%;
        color: $color-white;
        text-align: center;
        p {
          color: $color-white;
          display: block;
          &:last-child {
            font-size: 22px;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
