/* =================
    Root Color
=================*/

:root {
  --color-white: #ffffff;
  --color-black: #242528;
  --color-gBlack2: #e4e4e4;
  --color-gBlack3: #c2c2c2;
  --color-gBlack4: #626262;
  --color-tr: transparent;
}

//Color
$color-white: var(--color-white);
$color-black: var(--color-black);
$color-gBlack2: var(--color-gBlack2);
$color-gBlack3: var(--color-gBlack3);
$color-gBlack4: var(--color-gBlack4);
$color-tr: var(--color-tr);

/* custom colors */

$color-main-bg: #070a29;
$color-main-bg-tr: rgba(7, 10, 41, 0.7);
$color-1-blue: #16a4b3;
$color-2-purple: #af69ed;
$color-2-purple-lt: #e2c1ff;
$color-3-green: #85bb65;
$color-4-yellow: #fac317;
$color-5-red: #92131a;
$color-6-dblue: #223962;
$color-7-orange: #f7941e;
$color-red-light: #ff444d;
$color-shopping-gray: #f7f7f9;

$btn-grad-1: rgb(75, 150, 255);
$btn-grad-2: rgb(167, 181, 255);
$btn-grad-3: #ff708c;
$btn-grad-4: rgb(255, 162, 182);
$btn-grad-5: rgb(0, 23, 143);
$btn-grad-tr-1: rgba(75, 150, 255, 0.75);
$btn-grad-tr-2: rgba(167, 181, 255, 0.75);
$btn-grad-tr-3: rgba(255, 112, 140, 0.75);
$btn-grad-tr-4: rgba(255, 162, 182, 0.75);
$btn-grad-tr-5: rgba(0, 23, 143, 0.75);

$color-placeholder: #a6a6a6;

.color-logo-yellow {
  color: $color-4-yellow;
}
.color-red-light {
  color: $color-red-light !important;
}
