.top-highlight {
  padding: 0px 20px;
  height: 40px;
  line-height: 40px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  color: $color-white;
  position: relative;
  background: linear-gradient(
    to right,
    $btn-grad-tr-1 0%,
    $btn-grad-tr-2 25%,
    $color-3-green 50%,
    $btn-grad-tr-4 75%,
    $btn-grad-tr-5 100%
  );
  img {
    height: 40px;
    display: inline-block;
  }
  .top-bar-close {
    position: absolute;
    display: flex;
    align-items: flex-start;
    width: 20px;
    height: 100%;
    text-align: center;
    top: 0px;
    right: 0px;
    font-size: 18px;
    cursor: pointer;
    img {
      display: block;
      height: auto;
      max-height: 20px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .top-highlight {
    line-height: 20px;
    height: 40px;
    font-size: 12px;
    img {
      height: 50px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .top-highlight {
    line-height: 16px;
    padding: 0 5px;
    height: 40px;
    font-size: 10px;
    img {
      height: 30px;
    }
  }
}

.hamburger {
  cursor: pointer;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 999;
  input {
    display: none;
  }
  svg {
    /* The size of the SVG defines the overall size */
    height: 3em;
    /* Define the transition for transforming the SVG */
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .line {
    fill: none;
    stroke: white;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3;
    /* Define the transition for transforming the Stroke */
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    &.shopping-line {
      stroke: $color-2-purple;
    }
  }
  .line-top-bottom {
    stroke-dasharray: 12 63;
  }
  input:checked + svg {
    transform: rotate(-45deg);
    .line-top-bottom {
      stroke-dasharray: 20 300;
      stroke-dashoffset: -32.42;
    }
  }
}

header.site-header {
  position: absolute;
  width: 100%;
  padding: 15px 20px;
  z-index: 999;
  .main-header-col {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .brand-img {
    display: inline-block;
    width: 110px;
    cursor: pointer;
  }
  .brand-img.brand-img-careers {
    width: 240px;
    cursor: pointer;
  }

  .desktop-menu {
    align-items: center;
    margin-left: 60px;
    justify-content: space-between;
    width: 100%;
    > div {
      display: inline-flex;
      align-items: center;
    }
    .menu-bar-link {
      display: inline-block;
      margin-right: 25px;
      color: $color-white;
      text-decoration: none;
      cursor: pointer;
      font-size: 18px;
      line-height: 26px;
      @extend .transition-all;
      &:hover,
      &.active {
        color: $btn-grad-3;
      }
    }
    .user-icon {
      display: inline-block;
      width: 26px;
      height: 26px;
      cursor: pointer;
      img {
        display: block;
        margin: 0 auto;
        padding: 1px;
      }
    }
    &.shopping-menu {
      .menu-bar-link {
        color: $color-black;
        &:hover,
        &.active {
          color: $btn-grad-3;
        }
      }
    }
  }
  &.sticky-header {
    position: fixed;
    top: 0;
    background-color: $color-main-bg-tr;
    backdrop-filter: blur(3px);
    .brand-img {
      width: 60px;
    }
    .brand-img.brand-img-careers {
      width: 160px;
    }
    .desktop-menu {
      &.shopping-menu {
        .menu-bar-link {
          color: $color-white;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  header.site-header {
    .brand-img.brand-img-careers {
      width: 150px;
    }
  }
}

.menu-bar-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-main-bg-tr;
  backdrop-filter: blur(10px);
  z-index: 99;
  .menu-bar-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    transition: all 1s ease;
    position: relative;
    height: 100%;
  }
  .menu-bar-front {
    display: flex;
    z-index: 999;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    position: absolute;
    backface-visibility: hidden;
    padding: 40px 20px;
    border-radius: 15px;
    box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 1),
      inset -1px -1px 5px rgba(255, 255, 255, 0.6);

    overflow: hidden;
    .menu-bar-link,
    label {
      width: 250px;
      text-align: center;
      color: $color-white;
      text-decoration: none;
      cursor: pointer;
      font-size: 20px;
      &:hover {
        color: $btn-grad-3;
      }
    }
  }
  .menu-bar-back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: absolute;
    backface-visibility: hidden;
    transform: rotateY(-180deg);
    padding: 40px 20px 30px;
    border-radius: 15px;
    box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 1),
      inset -1px -1px 5px rgba(255, 255, 255, 0.6);
    overflow: hidden;
    .otp-boxes-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .otp-box {
        width: 50px !important;
        min-height: 50px;
        color: $color-white;
        outline: none;
        transition: 0.35s;
        padding: 0px 12px;
        background-color: #070a29;
        border-radius: 10px;
        border: 2px solid #070a29;
        box-shadow: 6px 6px 10px rgb(0, 0, 0),
          1px 1px 10px rgba(255, 255, 255, 0.6);
      }
    }
    .menu-bar-link,
    label {
      width: 250px;
      text-align: center;
      color: $color-white;
      text-decoration: none;
      cursor: pointer;
      font-size: 20px;
      &:hover {
        color: $btn-grad-3;
      }
    }
  }

  .menu-bar-back.main-login-screen {
    transform: rotateY(0deg);
  }

  .form_details {
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 10px;
    color: $color-white;
  }

  .input {
    width: 245px;
    min-height: 45px;
    color: $color-white;
    outline: none;
    transition: 0.35s;
    padding: 0px 12px;
    background-color: $color-main-bg;
    border-radius: 30px;
    border: 2px solid $color-main-bg;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 1),
      1px 1px 10px rgba(255, 255, 255, 0.6);
  }

  .input::placeholder {
    color: #999;
  }

  .input:focus.input::placeholder {
    transition: 0.3s;
    opacity: 0;
  }

  .input:focus {
    transform: scale(1.05);
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 1),
      1px 1px 10px rgba(255, 255, 255, 0.6), inset 2px 2px 10px rgba(0, 0, 0, 1),
      inset -1px -1px 5px rgba(255, 255, 255, 0.6);
  }

  .signup_tog,
  label.signup_tog {
    // color: $color-white;
    cursor: pointer;
    position: absolute;
    width: 30px;
    // height: 24px;
    top: -3px;
    right: -5px;
  }

  #signup_toggle {
    display: none;
  }

  #signup_toggle:checked + .menu-bar-wrapper {
    transform: rotateY(-180deg);
  }
}

@media only screen and (max-width: 991px) {
  header.site-header {
    .brand-img {
      width: 80px;
    }
  }
  .menu-bar-container {
    .menu-bar-front {
      padding: 25px 20px;
      gap: 10px;
      bottom: 80px;

      .menu-bar-link,
      label {
        font-size: 16px;
      }
    }
    .menu-bar-back {
      padding: 25px 20px;
      gap: 10px;
      bottom: 80px;
      .menu-bar-link,
      label {
        font-size: 16px;
      }
    }
    .form_details {
      font-size: 18px;
    }
  }
}
.rewards-balances-wrapper {
  width: auto;
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: inset 2px 2px 10px rgb(0, 0, 0),
    inset -1px -1px 5px rgba(255, 255, 255, 0.6);
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 10px;
  gap: 6px;
  .curr-bal-block {
    // height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .img-blk {
      width: 24px;
      height: 24px;
      border-radius: 20px 0px 0px 20px;
      display: inline-flex;
      flex-wrap: wrap;
      align-content: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .text-blk {
      display: inline-block;
      font-size: 16px;
      line-height: 24px;
      color: $color-white;
      padding: 0 8px 0px 0px;
      border-radius: 0px 15px 15px 0px;
      font-family: "Anta", sans-serif;
      font-weight: 400;
      font-style: normal;
    }
  }
  &.shopping-balances-wrapper {
    .curr-bal-block {
      .text-blk {
        color: $color-2-purple;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  header.site-header {
    padding: 15px 5px;
    .brand-img {
      width: 60px;
    }
    .main-header-col {
      flex-wrap: wrap;
    }
    .reward-balance-main-wrapper {
      width: 100%;
      margin-top: 10px;
    }
  }
}
