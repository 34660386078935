.actual-game.whirlpool {
  .arrow-wrapper {
    position: absolute;
    left: calc(50% - 10px);
    top: 50px;
    z-index: 9;
  }

  .arrow {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 30px solid $color-main-bg;
    position: relative;
    top: 0;
    left: 0px;
  }
  .arrow:after {
    content: "";
    position: absolute;
    left: -10px;
    top: 30px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 30px solid $color-main-bg;
  }

  $wheel-radius: 150px;
  $wheel-radius-2: 250px;

  .wheel {
    background-color: white;
    width: $wheel-radius * 2;
    height: $wheel-radius * 2;
    margin-left: -$wheel-radius;
    margin-top: -$wheel-radius;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 2px solid white;
    overflow: hidden;
    box-shadow: 0px 0px 10px 10px #000;
    z-index: 1;
  }

  .wheel-2 {
    background-color: white;
    width: $wheel-radius-2 * 2;
    height: $wheel-radius-2 * 2;
    margin-left: -$wheel-radius-2;
    margin-top: -$wheel-radius-2;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 2px solid white;
    overflow: hidden;
    box-shadow: 0px 0px 10px 10px #000;
    z-index: 0;
  }

  $colors: #009f52 #ff9fe4 #5fdfff #ffff00 #ff0000 #bf5fff #009f52 #5fdfff
    #ff7700 #ff9fe4 #bf5fff #ffff00;

  @for $i from 1 through 12 {
    .wheel__segment:nth-of-type(#{$i}n) {
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-left-width: 38px;
      border-right-width: 38px;
      border-top: $wheel-radius solid nth($colors, $i);
      left: 50%;
      position: absolute;
      margin-left: -38px;
      transform-origin: 50% 100%;
      transform: rotate(#{($i - 1) * 30deg});
    }
  }

  @for $i from 1 through 12 {
    .wheel__segment-2:nth-of-type(#{$i}n) {
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-left-width: 65px;
      border-right-width: 65px;
      border-top: $wheel-radius-2 solid nth($colors, $i);
      left: 50%;
      position: absolute;
      margin-left: -65px;
      transform-origin: 50% 100%;
      transform: rotate(#{($i - 1) * 30deg});
    }
  }

  .number {
    display: block;
    color: black;
    font-family: "Eczar", serif;
    font-size: 30px;
    text-align: center;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    text-shadow: 3px 3px rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 30px;
    height: 30px;
    left: calc(50% - 30px);
    transform: translateX(50%);
    top: -145px;
  }

  .alphabet {
    color: black;
    font-family: "Eczar", serif;
    font-size: 40px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    text-shadow: 3px 3px rgba(0, 0, 0, 0.5);
    position: absolute;
    left: calc(50%);
    transform: translateX(-50%);
    top: -240px;
  }

  .reset-btn {
    background: -webkit-linear-gradient(#e0a639, #fdfd45, #e0a639);
    padding: 10px 50px;
    border: 0;
    position: absolute;
    left: 0;
    bottom: 0px;
    color: black;
    font-weight: 900;
    text-transform: uppercase;
    box-shadow: 1px 1px #9e6426, 2px 2px #9e6426, 3px 3px #9e6426;

    &:focus {
      outline: none;
    }

    &:disabled {
      box-shadow: none;
      opacity: 0.5;
      color: grey;
    }

    &:active {
      box-shadow: none;
    }
  }
  .game-result {
    font-size: 18px;
    color: $color-white;
    position: absolute;
    z-index: 99;
    left: 0;
    bottom: -36px;
  }
}
