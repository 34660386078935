.pixel-brain-main-wrapper {
  position: relative;
  height: 560px;
  padding-top: 40px;
  .game-btn-wrapper {
    position: absolute;
    width: 100%;
    bottom: 26px;
    &.inside {
      bottom: 26px;
    }
  }
  .message-before-game-start {
    width: 100%;
    padding: 15px;
    position: absolute;
    font-size: 20px;
    top: calc(50% - 125px);
    text-align: center;
    img {
      width: 250px;
      height: 250px;
      display: block;
      margin: 0 auto;
    }
    span {
      font-size: 30px;
    }
  }
}

.pixel-brain-wrapper {
  width: 100%;
  padding: 30px;
  padding-top: 0;
  position: relative;
  .counter-wrapper {
    text-align: end;
    .counter-text {
      display: inline-block;
      padding: 5px 8px;
      margin: 0 auto;
      width: auto;
      text-align: right;
      border-radius: 6px;
      border: 1px dotted $color-4-yellow;
    }
  }
}

.progress-bar-wrapper {
  position: relative;
  width: 500px;
  margin: 0px auto 60px;
  &::before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    background-image: url("../assets/img/gaming/progress-bar-bg.png");
    background-size: 100%;
  }
  .progress {
    height: 20px;
    border-radius: 20px;
    background-color: transparent;
    .progress-bar {
      background-color: $color-4-yellow;
    }
  }

  .question-num {
    position: absolute;
    left: -15px;
    top: -10px;
    width: 40px;
    height: 40px;
    border: 1px solid $color-white;
    font-size: 20px;
    font-weight: 700;
    border-radius: 50%;
    line-height: 38px;
    text-align: center;
    background-color: $color-5-red;
  }
  .gifts-wrapper {
    width: 100%;
    position: relative;
    span {
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      img {
        display: block;
        max-width: 100%;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        &.no-grayscale {
          -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
        }
      }
      &:nth-child(1) {
        left: calc(30% - 15px);
      }
      &:nth-child(2) {
        left: calc(60% - 15px);
      }
      &:nth-child(3) {
        left: calc(100% - 15px);
      }
    }
  }
}

.pixel-brain-question {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  border-bottom: 2px dotted $color-4-yellow;
  padding: 15px;
  // box-shadow: rgba(250, 195, 23, 0.4) 0px 5px, rgba(250, 195, 23, 0.3) 0px 10px,
  //   rgba(250, 195, 23, 0.2) 0px 15px, rgba(250, 195, 23, 0.1) 0px 20px;
}

.pixel-brain-answers-wrapper {
  .answer-block-col {
    margin-bottom: 20px;
  }
  .answer-block {
    width: 100%;
    font-size: 18px;
    height: 100%;
    font-weight: 500;
    border: 2px dotted $color-4-yellow;
    line-height: 24px;
    text-align: center;
    border-radius: 5px;
    padding: 15px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.win-message {
  font-size: 22px;
  color: $color-3-green;
  margin-bottom: 20px;
  text-align: center;
}

.incorrect-answer {
  font-size: 56px;
  line-height: 60px;
  color: $color-5-red;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: "Pixelify Sans", sans-serif;
  font-optical-sizing: auto;
  -webkit-text-stroke: 1px $color-white;
  span {
    font-size: 40px;
    display: block;
  }
  &.all-correct-answers {
    color: $color-3-green;
  }
}

@media only screen and (max-width: 767px) {
  .pixel-brain-main-wrapper {
    min-height: 560px;
    padding-top: 20px;
  }
  .progress-bar-wrapper {
    width: 300px;
    margin: 0px auto 30px;
    &::before {
      background-repeat: no-repeat;
      height: 13px;
    }
    .progress {
      height: 13px;
      .progress-bar {
        height: 12px;
      }
    }
    .question-num {
      top: -8px;
      width: 30px;
      height: 30px;
      font-size: 14px;
      font-weight: 600;
      line-height: 28px;
    }
    .gifts-wrapper {
      span {
        width: 20px;
        height: 20px;
        &:nth-child(1) {
          left: calc(30% - 10px);
        }
        &:nth-child(2) {
          left: calc(60% - 10px);
        }
        &:nth-child(3) {
          left: calc(100% - 10px);
        }
      }
    }
  }
  .pixel-brain-wrapper {
    padding-bottom: 15px;
    .counter-wrapper {
      .counter-text {
        font-size: 12px;
        padding: 2px 6px;
      }
    }
  }
  .pixel-brain-question {
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px dotted $color-4-yellow;
    margin-top: 10px;
  }
  .pixel-brain-answers-wrapper {
    .answer-block-col {
      margin-bottom: 10px;
    }
    .answer-block {
      font-size: 14px;
      font-weight: 400;
      border: 1px dotted $color-4-yellow;
      line-height: 18px;
      padding: 10px 6px;
    }
  }
  .incorrect-answer {
    font-size: 46px;
    line-height: 50px;
    font-weight: 500;
    span {
      font-size: 30px;
      display: block;
    }
  }
  .win-message {
    font-size: 18px;
  }
}
